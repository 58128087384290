define('incus-vastaanota/pods/webshop/paymentcallback/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        apixclient: _ember['default'].inject.service(),
        countryselector: _ember['default'].inject.service(),
        type: null,
        queryParams: ['ORDER_NUMBER', 'TIMESTAMP', 'PAID', 'METHOD', 'RETURN_AUTHCODE'],

        actions: {
            initIframe: function initIframe() {
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var url = this.get("countryselector.url");
                var that = this;
                iFrameResize({
                    log: false,
                    heightCalculationMethod: 'lowestElement',
                    initCallback: function initCallback(iframe) {
                        var userinfo = JSON.parse(window.localStorage.getItem("session"));
                        var locale = window.localStorage.getItem("locale");
                        if (url === "laskumappi" && locale === 'sv') {
                            locale = 'svfi';
                        }
                        userinfo[0].locale = locale;
                        userinfo[0].entryPoint = url;
                        var paymentData = that.get("model.paymentParams");
                        iframe.iFrameResizer.sendMessage({ userData: userinfo, paymentData: paymentData.type ? paymentData : null });
                    },
                    messageCallback: function messageCallback(msg) {
                        var command = JSON.parse(msg.message);
                        if (command.type === "paymentSuccess") {
                            if (userData) {
                                var promise = apixclient.GetSaldo(userData.UniqueCompanyID);
                                _ember['default'].RSVP.resolve(promise).then(function (data) {
                                    var saldo = data.data[0].Saldo;
                                    if (saldo < 90000) {
                                        that.set("session.user.0.Saldo", saldo);
                                        that.get("session").set("saldo", saldo);
                                    }
                                });
                            }
                        } else if (command.type === "paymentRedirect") {
                            window.location.replace(command.value);
                        }
                    } }, '#webshop');
            }
        }

    });
});