define("incus-vastaanota/pods/application/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 12
            },
            "end": {
              "line": 8,
              "column": 6
            }
          },
          "moduleName": "incus-vastaanota/pods/application/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "nav-image");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "admin-select");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "admin-user-selector", [], ["users", ["subexpr", "@mut", [["get", "session.adminSession.users", ["loc", [null, [13, 33], [13, 59]]]]], [], []], "user", ["subexpr", "@mut", [["get", "session.user.0.UniqueCompanyID", ["loc", [null, [13, 65], [13, 95]]]]], [], []]], ["loc", [null, [13, 4], [13, 98]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 37
                },
                "end": {
                  "line": 19,
                  "column": 102
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Saldo: ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "session.saldo", ["loc", [null, [19, 83], [19, 101]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 21,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "user-string");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" / ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" (");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(")\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "saldo-string");
            var el3 = dom.createTextNode("    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element9, 1, 1);
            morphs[1] = dom.createMorphAt(element9, 3, 3);
            morphs[2] = dom.createMorphAt(element9, 5, 5);
            morphs[3] = dom.createMorphAt(dom.childAt(element9, [7]), 1, 1);
            return morphs;
          },
          statements: [["content", "session.user.0.ContactPerson", ["loc", [null, [18, 6], [18, 40]]]], ["content", "session.user.0.Name", ["loc", [null, [18, 43], [18, 66]]]], ["content", "model.trimmedBusinessId", ["loc", [null, [18, 68], [18, 95]]]], ["block", "unless", [["get", "session.isInvoicingCustomer", ["loc", [null, [19, 47], [19, 74]]]]], [], 0, null, ["loc", [null, [19, 37], [19, 113]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 4
              },
              "end": {
                "line": 24,
                "column": 86
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-bars nav-bars-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "incus-vastaanota/pods/application/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "session.showItems.adminSelector", ["loc", [null, [11, 10], [11, 41]]]]], [], 0, 1, ["loc", [null, [11, 4], [21, 11]]]], ["block", "if", [["get", "session.user", ["loc", [null, [24, 10], [24, 22]]]]], [], 2, null, ["loc", [null, [24, 4], [24, 93]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 6
                },
                "end": {
                  "line": 35,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-bolt");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.list"], [], ["loc", [null, [34, 11], [34, 30]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 4
              },
              "end": {
                "line": 37,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["list"], [], 0, null, ["loc", [null, [32, 6], [35, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 6
                },
                "end": {
                  "line": 44,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-exchange");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.traffic"], [], ["loc", [null, [43, 11], [43, 33]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 4
              },
              "end": {
                "line": 46,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["traffic"], [], 0, null, ["loc", [null, [41, 6], [44, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 6
                },
                "end": {
                  "line": 52,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-inbox");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.archive"], [], ["loc", [null, [51, 11], [51, 33]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 54,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["archives"], [], 0, null, ["loc", [null, [49, 6], [52, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 6
                },
                "end": {
                  "line": 61,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-plus-circle");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.createInvoice"], [], ["loc", [null, [60, 11], [60, 39]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 4
              },
              "end": {
                "line": 63,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["invoice.create.buy"], [], 0, null, ["loc", [null, [58, 6], [61, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 6
                },
                "end": {
                  "line": 70,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-plus-circle");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.createSalesInvoice"], [], ["loc", [null, [69, 11], [69, 44]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 4
              },
              "end": {
                "line": 72,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["invoice.create.sell"], [], 0, null, ["loc", [null, [67, 6], [70, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 6
                },
                "end": {
                  "line": 86,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-cog");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.tools"], [], ["loc", [null, [85, 11], [85, 31]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 4
              },
              "end": {
                "line": 88,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["tools"], [], 0, null, ["loc", [null, [83, 6], [86, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 91,
                  "column": 6
                },
                "end": {
                  "line": 94,
                  "column": 6
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "fa fa-cog");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["common.settings"], [], ["loc", [null, [93, 11], [93, 34]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 4
              },
              "end": {
                "line": 96,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nav-icon");
            dom.setAttribute(el1, "onclick", "collapse()");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["subusersettings"], [], 0, null, ["loc", [null, [91, 6], [94, 18]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 99,
                "column": 6
              },
              "end": {
                "line": 102,
                "column": 6
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-sign-out");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["common.logout"], [], ["loc", [null, [101, 11], [101, 32]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 4
            },
            "end": {
              "line": 104,
              "column": 4
            }
          },
          "moduleName": "incus-vastaanota/pods/application/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "nav-icon");
          dom.setAttribute(el1, "onclick", "collapse()");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[7] = dom.createMorphAt(dom.childAt(fragment, [11]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "session.showItems.invoices", ["loc", [null, [30, 10], [30, 36]]]]], [], 0, null, ["loc", [null, [30, 4], [37, 11]]]], ["block", "if", [["get", "session.showItems.traffic", ["loc", [null, [39, 10], [39, 35]]]]], [], 1, null, ["loc", [null, [39, 4], [46, 11]]]], ["block", "if", [["get", "session.showItems.archives", ["loc", [null, [47, 10], [47, 36]]]]], [], 2, null, ["loc", [null, [47, 4], [54, 11]]]], ["block", "if", [["get", "session.showItems.buy", ["loc", [null, [56, 10], [56, 31]]]]], [], 3, null, ["loc", [null, [56, 4], [63, 11]]]], ["block", "if", [["get", "session.showItems.sell", ["loc", [null, [65, 10], [65, 32]]]]], [], 4, null, ["loc", [null, [65, 4], [72, 11]]]], ["block", "if", [["get", "session.showItems.tools", ["loc", [null, [81, 10], [81, 33]]]]], [], 5, null, ["loc", [null, [81, 4], [88, 11]]]], ["block", "unless", [["get", "session.showItems.tools", ["loc", [null, [89, 14], [89, 37]]]]], [], 6, null, ["loc", [null, [89, 4], [96, 15]]]], ["block", "link-to", ["logout"], [], 7, null, ["loc", [null, [99, 6], [102, 18]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 136,
                "column": 6
              },
              "end": {
                "line": 136,
                "column": 50
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["common.pricing"], [], ["loc", [null, [136, 28], [136, 50]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 137,
                "column": 6
              },
              "end": {
                "line": 137,
                "column": 46
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["common.terms"], [], ["loc", [null, [137, 26], [137, 46]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 125,
              "column": 0
            },
            "end": {
              "line": 142,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/application/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("footer");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "footer-left");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" | ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" |\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" |\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element3, [3]);
          var element7 = dom.childAt(element6, [3]);
          var element8 = dom.childAt(element7, [5]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element5, 1, 1);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          morphs[3] = dom.createAttrMorph(element6, 'class');
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[5] = dom.createMorphAt(element7, 1, 1);
          morphs[6] = dom.createMorphAt(element7, 3, 3);
          morphs[7] = dom.createAttrMorph(element8, 'href');
          morphs[8] = dom.createMorphAt(element8, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["footer.company"], [], ["loc", [null, [128, 8], [128, 30]]]], ["inline", "t", ["footer.address"], [], ["loc", [null, [130, 6], [130, 28]]]], ["inline", "t", ["footer.vatNumber"], [], ["loc", [null, [130, 31], [130, 55]]]], ["attribute", "class", ["subexpr", "if", [["get", "session.showChat", ["loc", [null, [133, 30], [133, 46]]]], "fakturamappen-chat-margin footer-right", "footer-right"], [], []]], ["inline", "t", ["footer.customerService"], [], ["loc", [null, [134, 8], [134, 38]]]], ["block", "link-to", ["pricing"], [], 0, null, ["loc", [null, [136, 6], [136, 62]]]], ["block", "link-to", ["terms"], [], 1, null, ["loc", [null, [137, 6], [137, 58]]]], ["attribute", "href", ["concat", [["subexpr", "mail-to", [["subexpr", "t", ["footer.serviceEmail"], [], ["loc", [null, [138, 25], [138, 50]]]]], [], ["loc", [null, [138, 15], [138, 52]]]]]]], ["inline", "t", ["footer.serviceEmail"], [], ["loc", [null, [138, 54], [138, 81]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 0
              },
              "end": {
                "line": 175,
                "column": 0
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("footer");
            dom.setAttribute(el1, "id", "neopost-footer");
            dom.setAttribute(el1, "role", "contentinfo");
            dom.setAttribute(el1, "class", "clearfix");
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "region-footer");
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "neopost-social-network");
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "block-social");
            var el5 = dom.createTextNode("\n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "footer-content");
            var el6 = dom.createTextNode("Join us on :");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("ul");
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "facebook");
            dom.setAttribute(el7, "href", "https://www.facebook.com/neopost");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-facebook");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "google_plus");
            dom.setAttribute(el7, "href", "https://plus.google.com/+neopost‎/posts");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-google-plus");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "twitter");
            dom.setAttribute(el7, "href", "http://www.twitter.com/NeopostGroup");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-twitter");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "linkedin");
            dom.setAttribute(el7, "href", "http://www.linkedin.com/company/neopost");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-linkedin");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "pinterest");
            dom.setAttribute(el7, "href", "https://www.pinterest.com/neopostgroup/");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-pinterest");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "instagram");
            dom.setAttribute(el7, "href", "https://instagram.com/neopostgroup/");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-instagram");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "slideshare");
            dom.setAttribute(el7, "href", "http://fr.slideshare.net/neopostgroup");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-slideshare");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "class", "youtube");
            dom.setAttribute(el7, "href", "http://www.youtube.com/user/TheNeopostGroup");
            dom.setAttribute(el7, "target", "_blank");
            var el8 = dom.createElement("i");
            dom.setAttribute(el8, "class", "fa fa-youtube");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n        ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "neopost-footer-left");
            var el5 = dom.createElement("a");
            dom.setAttribute(el5, "href", "/");
            dom.setAttribute(el5, "title", "");
            dom.setAttribute(el5, "class", "active");
            var el6 = dom.createTextNode("Neopost Finland © ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "neopost-footer-middle");
            var el5 = dom.createElement("a");
            dom.setAttribute(el5, "href", "/yksityisyydensuoja");
            dom.setAttribute(el5, "image", "0");
            dom.setAttribute(el5, "image_hover", "0");
            var el6 = dom.createTextNode("Rekisteriseloste");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 186,
                    "column": 6
                  },
                  "end": {
                    "line": 188,
                    "column": 6
                  }
                },
                "moduleName": "incus-vastaanota/pods/application/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      puh. 09 7744 740 | ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                var el2 = dom.createTextNode("myylaskut@financelink.fi");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element1, 'href');
                return morphs;
              },
              statements: [["attribute", "href", ["concat", [["subexpr", "mail-to", ["myylaskut@financelink.fi"], [], ["loc", [null, [187, 34], [187, 72]]]]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 188,
                      "column": 6
                    },
                    "end": {
                      "line": 190,
                      "column": 6
                    }
                  },
                  "moduleName": "incus-vastaanota/pods/application/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("      puh. 09 7744 740 | ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  var el2 = dom.createTextNode("laskutus@financelink.fi");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element0, 'href');
                  return morphs;
                },
                statements: [["attribute", "href", ["concat", [["subexpr", "mail-to", ["laskutus@financelink.fi"], [], ["loc", [null, [189, 34], [189, 71]]]]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 190,
                      "column": 6
                    },
                    "end": {
                      "line": 192,
                      "column": 6
                    }
                  },
                  "moduleName": "incus-vastaanota/pods/application/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("      puh. 09 7744 740\n      ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 188,
                    "column": 6
                  },
                  "end": {
                    "line": 192,
                    "column": 6
                  }
                },
                "moduleName": "incus-vastaanota/pods/application/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "is-equal", [["get", "session.user.0.FlFactoringId", ["loc", [null, [188, 26], [188, 54]]]], "Premium"], [], ["loc", [null, [188, 16], [188, 65]]]]], [], 0, 1, ["loc", [null, [188, 6], [192, 6]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 175,
                  "column": 0
                },
                "end": {
                  "line": 196,
                  "column": 0
                }
              },
              "moduleName": "incus-vastaanota/pods/application/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("footer");
              var el2 = dom.createTextNode("\n  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "footer-left");
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h3");
              var el4 = dom.createTextNode("Finance Link Oy");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              var el4 = dom.createTextNode("\n      Mannerheiminaukio 1 B, 00100 Helsinki | Y-tunnus: 2006432-7\n    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "footer-right");
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h3");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n  ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [0, 3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["footer.customerService"], [], ["loc", [null, [184, 8], [184, 38]]]], ["block", "if", [["subexpr", "is-equal", [["get", "session.user.0.FlFactoringId", ["loc", [null, [186, 22], [186, 50]]]], "Flex"], [], ["loc", [null, [186, 12], [186, 58]]]]], [], 0, 1, ["loc", [null, [186, 6], [192, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 175,
                "column": 0
              },
              "end": {
                "line": 196,
                "column": 0
              }
            },
            "moduleName": "incus-vastaanota/pods/application/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "is-equal", [["get", "model.skin", ["loc", [null, [175, 20], [175, 30]]]], "financelink"], [], ["loc", [null, [175, 10], [175, 45]]]]], [], 0, null, ["loc", [null, [175, 0], [196, 0]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 142,
              "column": 0
            },
            "end": {
              "line": 196,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/application/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-equal", [["get", "model.skin", ["loc", [null, [142, 20], [142, 30]]]], "neopost"], [], ["loc", [null, [142, 10], [142, 41]]]]], [], 0, 1, ["loc", [null, [142, 0], [196, 0]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 197,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/application/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "overlay");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1, "class", "navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navbar-link");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "mobileNav");
        dom.setAttribute(el2, "class", "nav-icons-wrapper responsive-nav");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  function collapse() {\n    let nav = document.getElementById(\"mobileNav\");\n    if (nav.className === \"nav-icons-wrapper\") {\n      nav.className += \" responsive-nav\";\n    } else {\n      nav.className = \"nav-icons-wrapper\";\n    }\n  }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [6]);
        var element11 = dom.childAt(element10, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(element11, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [10]), 1, 1);
        morphs[6] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "ember-notify", [], ["messageStyle", "bootstrap"], ["loc", [null, [1, 0], [1, 42]]]], ["content", "css-spinner", ["loc", [null, [1, 43], [1, 58]]]], ["block", "link-to", ["login"], [], 0, null, ["loc", [null, [6, 12], [8, 18]]]], ["block", "if", [["get", "session.user", ["loc", [null, [10, 10], [10, 22]]]]], [], 1, null, ["loc", [null, [10, 4], [26, 11]]]], ["block", "if", [["get", "session.user", ["loc", [null, [29, 10], [29, 22]]]]], [], 2, null, ["loc", [null, [29, 4], [104, 11]]]], ["content", "outlet", ["loc", [null, [122, 2], [122, 12]]]], ["block", "if", [["subexpr", "is-equal", [["get", "model.skin", ["loc", [null, [125, 16], [125, 26]]]], "apix"], [], ["loc", [null, [125, 6], [125, 34]]]]], [], 3, 4, ["loc", [null, [125, 0], [196, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});