define('incus-vastaanota/services/session', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {

  var tawkSRC = _incusVastaanotaConfigEnvironment['default'].build === "laskumappi" ? 'https://embed.tawk.to/5e1c71d07e39ea1242a45d45/default' : 'https://embed.tawk.to/5ca1edb66bba46052800a81c/default';

  exports['default'] = _ember['default'].Service.extend({
    user: null,
    showItems: null,
    showChat: false,
    apixclient: _ember['default'].inject.service(),
    laskumappiSkin: null,
    adminSession: null,
    saldo: 0,
    isInvoicingCustomer: false,
    redirection: null,
    routing: _ember['default'].inject.service('-routing'),

    init: function init() {
      this._super();
      window.localStorageAlias = window.localStorage;
      if (!window.localStorage) {
        window.localStorageAlias = {};
        window.localStorageAlias.removeItem = function () {};
        window.localStorageAlias.getItem = function (item) {
          return this[item];
        };
        window.localStorageAlias.setItem = function (item, value) {
          this[item] = value;
        };
      }

      try {
        var user = window.localStorageAlias.getItem("session");
        if (user) {
          var that = this;
          this.handleUpdateSession(that);
          user = JSON.parse(user);
          if (user[0].loggedIn) {
            if (moment(user[0].loggedIn).add(5, 'days').isBefore(moment())) {
              this.logout();
              return;
            }
          }
          var adminSession = window.localStorageAlias.getItem("adminSession");
          if (adminSession) {
            this.set("adminSession", JSON.parse(adminSession));
          }
          this.set("user", user);
          this.set("showItems", user[0].ShowItems);
          if (user[0].laskumappiSkin && user[0].laskumappiSkin === "financelink") {
            this.logout();
          }
          if (_incusVastaanotaConfigEnvironment['default'].environment === 'production' || _incusVastaanotaConfigEnvironment['default'].environment === 'test') {
            if (window.Tawk_API) {
              window.Tawk_API.showWidget();
            } else {
              window.Tawk_API = {}, window.Tawk_LoadStart = new Date();
              window.Tawk_API.visitor = {
                name: user[0].ContactPerson + " " + user[0].Name,
                email: user[0].Email
              };
              var s1 = document.createElement("script"),
                  s0 = document.getElementsByTagName("script")[0];
              s1.async = true;
              s1.src = tawkSRC;
              s1.charset = 'UTF-8';
              s1.setAttribute('crossorigin', '*');
              s0.parentNode.insertBefore(s1, s0);
            }
            this.set("showChat", true);
          }
        }
      } catch (e) {
        _ember['default'].Logger.log("Failed to read user", e);
      }
    },

    /**
    * Returns true when logged on.
    */
    authenticated: _ember['default'].computed("user", {
      get: function get() {
        return this.get("user") !== null;
      }
    }),

    /**
    * Provide with user data, arbitrary but expecting truish value.
    */
    login: function login(data, username, password) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var apixclient = _this.get("apixclient");
        _ember['default'].Logger.log(data);
        var loginData = data;
        var loggedIn = moment(new Date());
        loginData.data[0].loggedIn = loggedIn;
        if (loginData.data[0].SubuserData) {
          loginData.data[0].ContactPerson = loginData.data[0].SubuserData.username;
          loginData.data[0].Email = loginData.data[0].SubuserData.username;
        }
        // XXX Should we process this data here somehow?
        _ember['default'].Logger.log("Ytunnus: " + data.data[0].Ytunnus);
        _ember['default'].Logger.log("TransferID: " + data.data[0].TransferID);
        _ember['default'].Logger.log("TransferKey: " + data.data[0].TransferKey);
        apixclient._xAuthServerGetToken(data.data[0]);
        apixclient.listContract(data.data[0].TransferID, data.data[0].TransferKey, data.data[0].UniqueCompanyID).then(function (data) {
          var showItems = {
            archives: true,
            traffic: true,
            invoices: true,
            tools: true,
            sell: true,
            buy: true,
            subusers: false,
            ProcountorAPI: true,
            admin: false,
            filemanager: false,
            debtCollect: false,
            webshop: true,
            adminSelector: false
          };
          var laskumappiSkin = data.data.find(function (contract) {
            return contract.ContractType === 'LaskumappiSkin' && contract.ContractStatus === 'Active';
          }) || false;
          if (laskumappiSkin && laskumappiSkin.Parameters === "financelink") {
            reject(false);
            return;
          }

          var contract = data.data.find(function (contract) {
            return contract.ContractType === 'LaskumappiHideItems' && contract.ContractStatus === 'Active';
          }) || false;
          if (contract) {
            var hideItems = contract.Parameters.split(",");
            for (var item in showItems) {
              if (hideItems.includes(item)) {
                showItems[item] = false;
              }
            }
          }
          var filemanager = data.data.find(function (contract) {
            return contract.ContractType == 'StorageFileSystem' && contract.ContractStatus == 'Active';
          });
          var debtCollect = data.data.find(function (contract) {
            return contract.ContractType == 'LaskumappiDebtCollect' && contract.ContractStatus == 'Active';
          });
          showItems.filemanager = filemanager ? true : false;
          showItems.debtCollect = debtCollect ? true : false;
          var subuserContract = data.data.find(function (contract) {
            return contract.ContractType === 'Subuser' && contract.ContractStatus === 'Active';
          }) || false;
          if (subuserContract) {
            showItems.subusers = true;
          }
          if (loginData.data[0].SubuserData) {
            showItems.tools = false;
            showItems.sell = loginData.data[0].SubuserData.createSellInvoice;
            showItems.invoices = loginData.data[0].SubuserData.createPurchaseInvoice;
            showItems.buy = loginData.data[0].SubuserData.createPurchaseInvoice;
            showItems.traffic = showItems.archives = showItems.buy || showItems.sell;
            showItems.adminSelector = loginData.data[0].SubuserData.adminSelector || false;
          }

          if (_incusVastaanotaConfigEnvironment['default'].environment === 'production' || _incusVastaanotaConfigEnvironment['default'].environment === 'test') {
            if (window.Tawk_API) {
              window.Tawk_API.showWidget();
            } else {
              window.Tawk_API = {}, window.Tawk_LoadStart = new Date();
              window.Tawk_API.visitor = {
                name: loginData.data[0].ContactPerson + " " + loginData.data[0].Name,
                email: loginData.data[0].Email
              };
              var s1 = document.createElement("script"),
                  s0 = document.getElementsByTagName("script")[0];
              s1.async = true;
              s1.src = tawkSRC;
              s1.charset = 'UTF-8';
              s1.setAttribute('crossorigin', '*');
              s0.parentNode.insertBefore(s1, s0);
            }
            _this.set("showChat", true);
          }

          apixclient.listUsers(loginData.data[0].TransferID, loginData.data[0].TransferKey, loginData.data[0].UniqueCompanyID).then(function (data) {
            if (data.callStatus.success === "OK" /* && !loginData.data[0].SubuserData */) {
                var users = data.data;
                var currentUser = data.data[0];
                showItems.adminSelector = true;
                if (!loginData.data[0].SubuserData) {
                  showItems.admin = true;
                } else {
                  showItems.adminSelector = loginData.data[0].SubuserData.adminSelector;
                  currentUser.Email = loginData.data[0].SubuserData.username;
                  currentUser.ContactPerson = loginData.data[0].SubuserData.username;
                  currentUser.SubuserData = loginData.data[0].SubuserData;
                  users.splice(0, 1, currentUser);
                }
                var adminSession = {
                  mainUser: {
                    tid: loginData.data[0].TransferID,
                    tkey: loginData.data[0].TransferKey,
                    uid: loginData.data[0].UniqueCompanyID,
                    subuserData: loginData.data[0].SubuserData || null
                  },
                  currentUser: currentUser,
                  users: users
                };
                _this.set("adminSession", adminSession);
                window.localStorageAlias.setItem("adminSession", JSON.stringify(adminSession));
              }

            loginData.data[0].ShowItems = showItems;
            _this.set("showItems", showItems);
            _this.set("user", loginData.data);
            _this.get("user").notifyPropertyChange();
            window.localStorageAlias.setItem("session", JSON.stringify(loginData.data));

            var that = _this;
            _this.handleUpdateSession(that);

            if (laskumappiSkin && laskumappiSkin.Parameters === "financelink") {
              resolve("invoice.create.sell");
            } else {
              resolve("traffic");
            }
          })['catch'](function (error) {
            console.log(error);
          });
        });
      });
    },

    /**
    * Log out current user.
    */
    logout: function logout() {
      this.set("user", null);
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
        this.set("showChat", false);
      }
      this.set("showItems", null);
      this.set("adminSession", null);
      window.localStorageAlias.removeItem("session");
      window.localStorageAlias.removeItem("adminSession");
      window.localStorageAlias.removeItem("userData");
      this.get("routing").transitionTo("login");
    },
    idleLogout: function idleLogout() {
      var timer = undefined;
      var that = this;

      resetTimer();

      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.onmousedown = resetTimer; // catches touchscreen presses as well
      window.ontouchstart = resetTimer; // catches touchscreen swipes as well
      window.onclick = resetTimer; // catches touchpad clicks as well
      window.onkeydown = resetTimer;
      window.addEventListener("scroll", resetTimer, true);

      function userLogout() {
        if (timer) {
          clearTimeout(timer);
        }
        that.logout();
      }

      function resetTimer() {
        if (that.get("user")) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(userLogout, 3600000); // 1hrs
        }
      }
    },
    adminChangeUser: function adminChangeUser(user) {
      var _this2 = this;

      var mainUser = this.get("adminSession.mainUser");
      var apixclient = this.get("apixclient");
      apixclient.getUser(mainUser.tid, mainUser.tkey, user.UniqueID).then(function (data) {
        if (data.callStatus.success === "OK" && data.data[0]) {
          (function () {
            var apixclient = _this2.get("apixclient");

            if (mainUser.subuserData) {
              if (user.UniqueID === mainUser.uid) {
                data.data[0].TransferId = mainUser.tid;
                data.data[0].TransferKey = mainUser.tkey;
              }
            }
            //getUser and auth keys are different
            data.data[0].TransferID = data.data[0].TransferId;
            delete data.data[0].TransferId;

            data.data[0].UniqueCompanyID = data.data[0].UniqueID;
            delete data.data[0].UniqueID;

            data.data[0].LanguageCoded = data.data[0].Language;
            delete data.data[0].Language;

            data.data[0].Phonenumber = data.data[0].PhoneNumber;
            delete data.data[0].PhoneNumber;

            data.data[0].Www = data.data[0].WWW;
            delete data.data[0].WWW;

            data.data[0].Ytunnus = data.data[0]['y-tunnus'];
            delete data.data[0]['y-tunnus'];

            var loginData = data;
            var loggedIn = moment(new Date());
            loginData.data[0].loggedIn = loggedIn;

            apixclient._xAuthServerGetToken(loginData.data[0]);
            apixclient.listContract(data.data[0].TransferID, data.data[0].TransferKey, data.data[0].UniqueCompanyID).then(function (data) {
              var showItems = {
                posteljooni: true,
                archives: true,
                traffic: true,
                invoices: true,
                tools: true,
                sell: true,
                buy: true,
                subusers: false,
                ProcountorAPI: true,
                admin: true,
                adminSelector: true
              };
              var laskumappiSkin = data.data.find(function (contract) {
                return contract.ContractType === 'LaskumappiSkin' && contract.ContractStatus === 'Active';
              }) || false;
              if (laskumappiSkin && laskumappiSkin.Parameters === "financelink") {
                _this2.logout();
                return;
              }
              var contract = data.data.find(function (contract) {
                return contract.ContractType === 'LaskumappiHideItems' && contract.ContractStatus === 'Active';
              }) || false;
              if (contract) {
                var hideItems = contract.Parameters.split(",");
                for (var item in showItems) {
                  if (hideItems.includes(item)) {
                    showItems[item] = false;
                  }
                }
              }

              var filemanager = data.data.find(function (contract) {
                return contract.ContractType == 'StorageFileSystem' && contract.ContractStatus == 'Active';
              });
              var debtCollect = data.data.find(function (contract) {
                return contract.ContractType == 'LaskumappiDebtCollect' && contract.ContractStatus == 'Active';
              });
              showItems.filemanager = filemanager ? true : false;
              showItems.debtCollect = debtCollect ? true : false;

              var subuserContract = data.data.find(function (contract) {
                return contract.ContractType === 'Subuser' && contract.ContractStatus === 'Active';
              }) || false;
              if (subuserContract) {
                showItems.subusers = true;
              }
              if (mainUser.subuserData) {
                if (user.UniqueID === mainUser.uid) {
                  showItems.tools = false;
                  showItems.sell = showItems.posteljooni = mainUser.subuserData.createSellInvoice;
                  showItems.invoices = mainUser.subuserData.createPurchaseInvoice;
                  showItems.buy = mainUser.subuserData.createPurchaseInvoice;
                  showItems.traffic = showItems.archives = showItems.buy || showItems.sell;
                  showItems.adminSelector = mainUser.subuserData.adminSelector || false;
                }
                showItems.subusers = false;
                showItems.admin = false;
              }
              if (_incusVastaanotaConfigEnvironment['default'].environment === 'production' || _incusVastaanotaConfigEnvironment['default'].environment === 'test') {
                if (window.Tawk_API) {
                  window.Tawk_API.showWidget();
                } else {
                  window.Tawk_API = {}, window.Tawk_LoadStart = new Date();
                  window.Tawk_API.visitor = {
                    name: loginData.data[0].ContactPerson + " " + loginData.data[0].Name,
                    email: loginData.data[0].Email
                  };
                  var s1 = document.createElement("script"),
                      s0 = document.getElementsByTagName("script")[0];
                  s1.async = true;
                  s1.src = tawkSRC;
                  s1.charset = 'UTF-8';
                  s1.setAttribute('crossorigin', '*');
                  s0.parentNode.insertBefore(s1, s0);
                }
                _this2.set("showChat", true);
              }
              loginData.data[0].ShowItems = showItems;
              _this2.set("showItems", showItems);
              _this2.set("user", loginData.data);
              _this2.set("adminSession.currentUser", user);
              window.localStorageAlias.setItem("session", JSON.stringify(loginData.data));
              window.localStorageAlias.setItem("adminSession", JSON.stringify(_this2.get("adminSession")));

              var moveTo = "traffic.pitstop.list";
              if (!showItems.sell) {
                moveTo = "traffic.received";
              }
              _this2.get("routing").transitionTo(moveTo);
              setTimeout(function () {
                window.location.reload(false);
              }, 1000);
              //   window.location.reload(false)
            })['catch'](function (error) {
              console.log(error);
            });
          })();
        }
      });
    },
    handleUpdateSession: function handleUpdateSession(that) {
      that.idleLogout();
    }
  });
});