define("incus-vastaanota/pods/archives/sent/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({

    actions: {
      selectInvoice: function selectInvoice(i) {
        var array = this.get("model.selectedInvoices");
        var index = array.findIndex(function (item) {
          return item.storageID === i.storageID;
        });
        if (index > -1) {
          array.removeAt(index);
        } else {
          this.get("model.selectedInvoices").pushObject({ storageID: i.storageID, documentID: i.documentID });
          console.log(i);
        }
      },
      selectCollector: function selectCollector(evt) {
        this.get("model").set("selectedCollector", evt.target.value);
      },
      openModal: function openModal() {
        this.get("model").set("showModal", true);
      },
      closeModal: function closeModal() {
        this.get("model").set("showModal", false);
      },
      emptyBubbler: function emptyBubbler() {}
    }

  });
});