define("incus-vastaanota/pods/tools/settings/route", ["exports", "ember", "incus-vastaanota/utils/email", "incus-vastaanota/config/environment", "incus-vastaanota/utils/countrycodes"], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsCountrycodes) {
  exports["default"] = _ember["default"].Route.extend({
    apixclient: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    model: function model() {
      var _this = this;

      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var arrivalNotification = null;
      var arrivalSms = null;
      var dueDateNotification = null;
      var dueDateSms = null;
      var url = this.get("countryselector.url");

      var contracts = [];
      var oauth_contracts = {
        procountor: { send: false, receive: false }
      };

      return apixclient.listContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then(function (data) {
        var contractInitialState = url === "laskumappi" ? {
          categories: {
            send: false,
            receive: false,
            archive: false
          },
          send: {
            duplicateCheck: false,
            accounting: false,
            priority: false
          },
          /*             consumerInvoicing: false,
                      edi: false,
                      debtCollect: false,
                      factoringCopy: false, */
          receive: {
            arrivalNotification: false,
            expiryNotification: false,
            scanning: false,
            emailScan: false
          }
        } : //            edi: false
        {
          categories: {
            send: false,
            receive: false,
            archive: false
          },
          receive: {
            arrivalNotification: false,
            expiryNotification: false
          }
        };

        var categories = url === "laskumappi" ? [{
          name: "categories.send",
          apiName: "Send",
          field: false
        }, {
          name: "send.duplicateCheck",
          apiName: "Dupcheck",
          field: false
        }, {
          name: "send.accounting",
          apiName: "Accountingcopy",
          field: false,
          properties: {
            hasEditableParameter: true
          }
        }, {
          name: "send.priority",
          apiName: "Priority",
          field: false
        },
        /*        {
                 name: "send.sendToCollector",
                 apiName: "SendToCollector",
                 field: false,
               }, */
        /*  {
           name: "send.consumerInvoicing",
           apiName: "B2Cinvoicing",
           field: false,
           properties: {
             isAdvert: true,
           },
         },
         {
           name: "send.edi",
           apiName: "SendEDI",
           field: false,
           properties: {
             isAdvert: true,
           },
         },
         {
           name: "send.debtCollect",
           apiName: "DebtCollect",
           field: false,
           properties: {
             isAdvert: true,
           },
         },
         {
           name: "send.factoringCopy",
           apiName: "FactoringCopy",
           field: false,
           properties: {
             isAdvert: true,
           },
         }, */

        {
          name: "categories.receive",
          apiName: "Receive",
          field: false
        }, {
          name: "receive.arrivalNotification",
          apiName: "InvoiceArrivalNotify",
          field: false,
          parameter: null,
          properties: {
            hasEditableParameter: true
          }
        }, {
          name: "receive.expiryNotification",
          apiName: "InvoicePaymentExpiryNotify",
          field: false,
          parameter: null,
          properties: {
            hasEditableParameter: true
          }
        }, {
          name: "receive.scanning",
          apiName: "Scanning",
          field: false,
          parameter: 'OC'
        }, /* {
           name: "receive.emailScan",
           apiName: "EMailScan",
           field: false,
           properties: {
            isAdvert: true,
           },
           }, */
        /*         {
                  name: "receive.edi",
                  apiName: "ReceiveEDI",
                  field: false,
                  properties: {
                    isAdvert: true,
                  },
                }, */
        {
          name: "categories.archive",
          apiName: "Archive",
          field: false
        }, {
          name: "categories.storagefilesystem",
          apiName: "StorageFileSystem",
          field: false
        }] :
        /*  {
           name: "categories.sendToCollector",
           apiName: "LaskumappiDebtCollect",
           parameter: "brang",
           field: false,
         } */ // -> Fakturamappen

        [{
          name: "categories.send",
          apiName: "Send",
          field: false
        }, {
          name: "categories.receive",
          apiName: "Receive",
          field: false
        }, {
          name: "receive.arrivalNotification",
          apiName: "InvoiceArrivalNotify",
          field: false,
          parameter: null,
          properties: {
            hasEditableParameter: true
          }
        }, {
          name: "receive.expiryNotification",
          apiName: "InvoicePaymentExpiryNotify",
          field: false,
          parameter: null,
          properties: {
            hasEditableParameter: true
          }
        }, {
          name: "categories.storagefilesystem",
          apiName: "StorageFileSystem",
          field: false
        }];

        var notify = _this.get("notify");

        if (data && typeof data === "object" && data.callStatus && data.callStatus.success === "OK") {
          var len = data.data.length;
          if (len > 0) {
            data.data.forEach(function (contract) {
              if (contract.ContractType) {
                contracts.pushObject(contract);
              }

              if (contract.ContractType && contract.ContractType === "ProcountorAPI") {
                var value = contract.Parameters;
                var values = [];
                if (value) {
                  var _values = value.split(";");
                  for (var i = _values.length - 1; i >= 0; i--) {
                    var params = _values[i].split("=");

                    if (params[0] === "SEND") {
                      oauth_contracts.procountor.send = true;
                    } else if (params[0] === "RECEIVE") {
                      oauth_contracts.procountor.receive = true;
                      oauth_contracts.procountor.receive_delay = params[1];
                    }
                  }
                }
              }
            });
          }
        } else {
          notify.error(_this.get("i18n").t("common.error").toString(), {
            closeAfter: 10000
          });
        }

        var model = _ember["default"].Object.extend({
          wrongFormat: false,
          eInvoiceAddress: "",
          imageURL: "",
          queryDone: false,
          name: "",
          businessID: "",
          realVatId: "",
          address: "",
          city: "",
          zip: "",
          country: "",
          bankAccounts: [],
          nameObs: _ember["default"].observer("name", function () {
            if (this.get("name")) {
              this.set("validations.name", true);
            } else {
              this.set("validations.name", false);
            }
          }),
          addressObs: _ember["default"].observer("address", function () {
            if (this.get("address")) {
              this.set("validations.address", true);
            } else {
              this.set("validations.address", false);
            }
          }),
          cityObs: _ember["default"].observer("city", function () {
            if (this.get("city")) {
              this.set("validations.city", true);
            } else {
              this.set("validations.city", false);
            }
          }),
          zipObs: _ember["default"].observer("zip", function () {
            if (this.get("zip")) {
              this.set("validations.zip", true);
            } else {
              this.set("validations.zip", false);
            }
          }),
          validations: {
            name: false,
            address: false,
            city: false,
            zip: false
          },
          rowId: 0,
          codes: (0, _incusVastaanotaUtilsCountrycodes["default"])(_this.get("i18n.locale")),
          build: _incusVastaanotaConfigEnvironment["default"].build,
          selectedCountry: "FI",
          contactPerson: "",
          phone: "",
          www: "",
          email: "",
          fax: "",
          penaltyInterest: "",
          selectedPaymentCondition: 1,
          paymentConditionDays: [1, 7, 14, 21, 30, 45, 60, 90],
          accountTypes: ["IBAN", "BANKGIRO", "PLUSGIRO", "OTHER"],
          i18n: _this.get("i18n"),
          countryselector: _this.get("countryselector"),
          /* copy locale to localstraoge on change */
          localeObserver: _ember["default"].observer("i18n.locale", function () {
            var locale = this.get("i18n.locale");
            this.set("codes", (0, _incusVastaanotaUtilsCountrycodes["default"])(locale));
            _ember["default"].Logger.log("Setting locale to", locale);
            if (!window.localStorageAlias) {
              window.localStorageAlias = window.localStorage;
              if (!window.localStorage) {
                window.localStorageAlias = {};
                window.localStorageAlias.removeItem = function () {};
                window.localStorageAlias.getItem = function (item) {
                  return this[item];
                };
                window.localStorageAlias.setItem = function (item, value) {
                  this[item] = value;
                };
              }
            }
            window.localStorageAlias.setItem("locale", locale);
          }),
          bankAccountsObserver: _ember["default"].observer("bankAccounts.@each.Value", "bankAccounts.@each.Type", function () {
            var accs = this.get("bankAccounts");
            for (var i = 0; accs.length > i; i++) {
              var row = this.get("bankAccounts").objectAt(i);
              switch (row.Type) {
                case "IBAN":
                  _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", IBAN.isValid(row.Value));
                  break;
                case "BANKGIRO":
                  _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", validateBankgiro(row.Value));
                  break;
                case "PLUSGIRO":
                  _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", validatePlusgiro(row.Value));
                case "OTHER":
                  _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", true);
                  break;
              }
            }
          }),
          logoLoaded: _ember["default"].computed("imageURL", "queryDone", function () {
            return _this.get("controller.model.imageURL") && _this.get("controller.model.queryDone");
          }),
          //USER SETTINGS

          newPass: '',
          oldPass: '',
          repeatPass: '',
          queryDone: false,
          settingsName: '',
          settingsAdditionalName: '',
          address: '',
          city: '',
          zip: '',
          country: '',
          codes: (0, _incusVastaanotaUtilsCountrycodes["default"])(_this.get("i18n.locale")),
          build: _incusVastaanotaConfigEnvironment["default"].build,
          selectedCountry: "FI",
          settingsContactPerson: '',
          settingsPhone: '',
          settingsWww: '',
          i18n: _this.get("i18n"),
          selectedAddress: "Official",
          lowellDebtCollectionActive: null,
          addressTypes: [{ value: "Official", tstring: "settings.userInfo.officialAddress" }, { value: "Billing", tstring: "settings.userInfo.billingAddress" }, { value: "Visiting", tstring: "settings.userInfo.visitingAddress" }],
          addresses: [{
            AddressType: "Official",
            Country: "",
            PostalCode: "",
            PostalOffice: "",
            Street1: "",
            Street2: ""
          }, {
            AddressType: "Visiting",
            Country: "",
            PostalCode: "",
            PostalOffice: "",
            Street1: "",
            Street2: ""
          }, {
            AddressType: "Billing",
            Country: "",
            PostalCode: "",
            PostalOffice: "",
            Street1: "",
            Street2: ""
          }],
          validations: {
            newPasswords: null,
            Official: {
              Country: '',
              PostalCode: '',
              PostalOffice: '',
              Street1: '',
              Street2: ''
            },
            Visiting: {
              Country: '',
              PostalCode: '',
              PostalOffice: '',
              Street1: '',
              Street2: ''
            },
            Billing: {
              Country: '',
              PostalCode: '',
              PostalOffice: '',
              Street1: '',
              Street2: ''
            }
          },
          addressesObserver: _ember["default"].observer("addresses.@each.Country", "addresses.@each.PostalCode", "addresses.@each.PostalOffice", "addresses.@each.Street1", "addresses.@each.Street2", function () {
            var _this2 = this;

            var addresses = this.get("addresses");
            /*jshint loopfunc: true */

            var _loop = function (i) {
              var empty = true;
              Object.keys(addresses[i]).forEach(function (key) {
                if (addresses[i][key] !== '' && key !== "AddressType") {
                  empty = false;
                }
              });
              if (!empty) {
                Object.keys(addresses[i]).forEach(function (key) {
                  if (addresses[i][key] !== '' && key !== "AddressType") {
                    _ember["default"].set(_this2.get("validations." + addresses[i].AddressType), key, 'is-valid');
                  } else {
                    _ember["default"].set(_this2.get("validations." + addresses[i].AddressType), key, 'is-invalid');
                  }
                });
              } else {
                Object.keys(addresses[i]).forEach(function (key) {
                  _ember["default"].set(_this2.get("validations." + addresses[i].AddressType), key, '');
                });
              }
            };

            for (var i = 0; addresses.length > i; i++) {
              _loop(i);
            }
          }),
          newPasswordsObserver: _ember["default"].observer("newPass", "repeatPass", function () {
            var newPass = this.get("newPass");
            var repeatPass = this.get("repeatPass");
            var value = '';
            if (newPass && repeatPass) {
              if (newPass === repeatPass & newPass.length > 4) {
                value = 'is-valid';
              } else {
                value = 'is-invalid';
              }
            }
            this.set("validations.newPasswords", value);
          })

        }).create({
          arrivalNotification: arrivalNotification,
          dueDateNotification: dueDateNotification,
          arrivalSms: arrivalSms,
          dueDateSms: dueDateSms,
          oauthContracts: oauth_contracts,
          contractInitialState: contractInitialState,
          categories: categories,
          isLaskumappi: _incusVastaanotaConfigEnvironment["default"].build === "laskumappi"
        });

        function validateBankgiro(value) {
          var parts = value.split("-");
          if (value.includes('-') && value.length >= 7) {
            if (parts[0].length === 3 | parts[0].length === 4 && parts[1].length === 4) {
              var serial = parts[0].concat(parts[1]);
              var sum = 0;
              var start = (serial.length - 1) % 2 === 0 ? 1 : 0;
              for (var i = 0; i < serial.length - 1; i++) {
                var digit = undefined;
                if (start === 1) {
                  if (i % 2 !== 0 && i !== 0) {
                    digit = Number(serial.charAt(i)) * 2;
                    if (digit >= 10) {
                      var firstNum = Number(digit.toString().charAt(0));
                      var secNum = Number(digit.toString().charAt(1));
                      sum += firstNum + secNum;
                    } else {
                      sum += digit;
                    }
                  } else {
                    digit = Number(serial.charAt(i));
                    sum += digit;
                  }
                } else {
                  if (i % 2 === 0 || i === 0) {
                    digit = Number(serial.charAt(i)) * 2;
                    if (digit >= 10) {
                      var firstNum = Number(digit.toString().charAt(0));
                      var secNum = Number(digit.toString().charAt(1));
                      sum += firstNum + secNum;
                    } else {
                      sum += digit;
                    }
                  } else {
                    digit = Number(serial.charAt(i));
                    sum += digit;
                  }
                }
              }
              if ((sum + Number(serial.charAt(serial.length - 1))) % 10 === 0) {
                return true;
              }
              return false;
            }
          }
          return false;
        }
        function validatePlusgiro(value) {
          var parts = value.split("-");
          if (parts[0].length === 6 || parts[0].length === 5 && parts[1].length === 1) {
            var serial = parts[0];
            var sum = 0;
            for (var i = 0; i < serial.length; i++) {
              var digit = undefined;
              if (parts[0].length === 6) {
                if (i % 2 !== 0 && i !== 0) {
                  digit = Number(serial.charAt(i)) * 2;
                  if (digit >= 10) {
                    var firstNum = Number(digit.toString().charAt(0));
                    var secNum = Number(digit.toString().charAt(1));
                    sum += firstNum + secNum;
                  } else {
                    sum += digit;
                  }
                } else {
                  digit = Number(serial.charAt(i));
                  sum += digit;
                }
              } else {
                if (i % 2 === 0 || i === 0) {
                  digit = Number(serial.charAt(i)) * 2;
                  if (digit >= 10) {
                    var firstNum = Number(digit.toString().charAt(0));
                    var secNum = Number(digit.toString().charAt(1));
                    sum += firstNum + secNum;
                  } else {
                    sum += digit;
                  }
                } else {
                  digit = Number(serial.charAt(i));
                  sum += digit;
                }
              }
            }
            if ((sum + Number(parts[1])) % 10 === 0) {
              return true;
            }
            return false;
          }
          return false;
        }
        _this.checkTokenStatus(model, "procountor", "refresh_token");
        return model;
      });
    },
    afterModel: function afterModel(model) {
      var _this3 = this;

      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var addresses = this.get("session").get("user")[1];
      if (Array.isArray(addresses)) {
        addresses = addressses[0];
      }
      model.set("settingsName", userData.Name);
      model.set("settingsAdditionalName", userData.AdditionalName);
      model.set("settingsContactPerson", userData.ContactPerson);
      model.set("settingsWww", userData.Www);
      model.set("settingsPhone", userData.Phonenumber);

      apixclient.FetchCutomerExtraInfo(userData).then(function (data) {
        model.set("realVatId", data.VatID || "");
      });

      apixclient.fetchInvoicingCustomerData(userData).then(function (data) {
        var Name = undefined,
            BusinessID = undefined,
            Street1 = "",
            City = "",
            PostCode = "",
            eInvoiceAddress = { Type: "EINVOICEADDRESS", Value: "" },
            ContactPerson = undefined,
            Www = undefined,
            PhoneNumber = undefined,
            Email = undefined,
            Fax = { Type: "FAX", Value: "" },
            CountryCode = "",
            Data = undefined,
            BankAccounts = undefined,
            Vatid = "",
            PenaltyInterest = undefined,
            DefaultPaymentDays = 1,
            rowId = 0;
        if (!data.AnyParty) {
          Name = userData.Name || "";
          BusinessID = userData.Ytunnus || "";
          ContactPerson = userData.ContactPerson || "";
          Www = userData.Www || "";
          PhoneNumber = userData.Phonenumber || "";
          Email = userData.Email || "";
          PenaltyInterest = "";
          if (addresses) {
            CountryCode = addresses.Country;
            Street1 = addresses.Street1;
            City = addresses.PostalOffice;
            PostCode = addresses.PostalCode;
          }
          if (CountryCode === "FI") {
            Vatid = "FI" + BusinessID.replace("-", "");
            eInvoiceAddress = {
              Type: "EINVOICEADDRESS",
              Value: "0037" + BusinessID.replace("-", "")
            };
          }
          BankAccounts = [];
          var Role = "ISSUER";
          var CustomerNumber = -1;
          Data = [{ Type: "EMAIL", Value: Email }, { Type: "PHONE", Value: PhoneNumber }, { Type: "WWW", Value: Www }, Fax, eInvoiceAddress];
          var _data = {
            ContactPerson: ContactPerson,
            AnyParty: {
              Name: Name,
              BusinessID: BusinessID,
              Street1: Street1,
              City: City,
              PostCode: PostCode,
              Role: Role,
              Vatid: Vatid,
              CustomerNumber: CustomerNumber,
              CountryCode: CountryCode
            },
            Data: Data
          };
          apixclient.updateInvoicingCustomerData(userData, _data).then(function (response) {
            if (response.success) {
              console.log("Updated customerdata");
            }
          });
        } else {
          DefaultPaymentDays = data.DefaultPaymentDays || 1;
          PenaltyInterest = data.AnyParty.PenaltyInterest || 0;
          Name = data.AnyParty.Name || "";
          BusinessID = data.AnyParty.BusinessID || "";
          Street1 = data.AnyParty.Street1 || "";
          City = data.AnyParty.City || "";
          CountryCode = data.AnyParty.CountryCode || "FI";
          PostCode = data.AnyParty.PostCode || "";
          ContactPerson = data.ContactPerson || "";
          Www = data.Data.find(function (row) {
            return row.Type === "WWW";
          }) || "";
          PhoneNumber = data.Data.find(function (row) {
            return row.Type === "PHONE";
          }) || "";
          Email = data.Data.find(function (row) {
            return row.Type === "EMAIL";
          }) || "";
          BankAccounts = data.Data.filter(function (row) {
            return row.Type === "BANKGIRO" || row.Type === "POSTGIRO" || row.Type === "IBAN" || row.Type === "OTHER";
          }) || [];
          BankAccounts = BankAccounts.map(function (account) {
            account.id = rowId++;
            return account;
          });
          eInvoiceAddress = data.Data.find(function (row) {
            return row.Type === "EINVOICEADDRESS";
          }) || "";
          Fax = data.Data.find(function (row) {
            return row.Type === "FAX";
          }) || "";
        }
        model.set("penaltyInterest", PenaltyInterest);
        model.set("name", Name);
        model.set("selectedCountry", CountryCode);
        model.set("businessID", BusinessID);
        model.set("address", Street1);
        model.set("city", City);
        model.set("zip", PostCode);
        model.set("contactPerson", ContactPerson);
        model.set("www", Www.Value || "");
        model.set("email", Email.Value || "");
        model.set("phone", PhoneNumber.Value || "");
        model.set("fax", Fax.Value || "");
        model.set("selectedPaymentCondition", DefaultPaymentDays);
        if (BankAccounts.length === 0) {
          BankAccounts = [{ Type: "IBAN", Value: "", id: 0 }];
          model.set("rowId", 1);
        }
        model.set("bankAccounts", BankAccounts);
        if (CountryCode === "FI" && (eInvoiceAddress === "" || eInvoiceAddress.Value === "")) {
          model.set("eInvoiceAddress", "0037" + BusinessID.replace("-", ""));
          _this3.get("controller").send("updateCustomer");
        } else if (eInvoiceAddress === "" || eInvoiceAddress.Value === "") {
          model.set("eInvoiceAddress", BusinessID.replace("-", ""));
          _this3.get("controller").send("updateCustomer");
        } else {
          model.set("eInvoiceAddress", eInvoiceAddress.Value || "");
        }
        model.set("queryDone", true);
      });

      apixclient.fetchInvoicingCustomerLogo(userData).then(function (data) {
        model.set("imageURL", data.Logo);
      });
      var addressData = this.get("session").get("user");
      addressData = addressData.slice(1, addressData.length);
      addressData.forEach(function (element) {
        var addressIndex = model.get("addresses").findIndex(function (addr) {
          return addr.AddressType === element.AddressType;
        });
        var addressToUpdate = model.get("addresses").objectAt(addressIndex);
        Object.keys(element).forEach(function (key) {
          _ember["default"].set(addressToUpdate, key, element[key]);
        });
      });
      apixclient.listContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then(function (data) {
        var categories = model.get("categories");
        var initialState = model.get("contractInitialState");

        var _loop2 = function (i) {
          var contract = data.data.find(function (contract) {
            return contract.ContractType === categories[i].apiName;
          });
          if (contract) {
            _ember["default"].set(categories.objectAt(i), 'field', true);
            _ember["default"].set(initialState, categories.objectAt(i).name, true);
            if (contract.ContractType === "InvoiceArrivalNotify" || contract.ContractType === "InvoicePaymentExpiryNotify" || contract.ContractType === "Accountingcopy") {
              var params = contract.Parameters.split(";").pop();
              _ember["default"].set(categories.objectAt(i), 'parameter', params);
            }
          }
        };

        for (var i = 0; i < categories.length; i++) {
          _loop2(i);
        }

        var lowellDebtCollectionContract = data.data.find(function (contract) {
          return contract.ContractType === "LowellSEDebtCollection";
        });
        if (lowellDebtCollectionContract) {
          if (lowellDebtCollectionContract.Parameters.includes("READY")) {
            model.set("lowellDebtCollectionActive", "READY");
          } else if (lowellDebtCollectionContract.Parameters.includes("WAITING")) {
            model.set("lowellDebtCollectionActive", "WAITING");
          } else {
            model.set("lowellDebtCollectionActive", "UNKNOWN");
          }
        }

        model.set("contractInitialState", initialState);
      })["catch"](function (error) {
        console.log(error);
      });
    },
    actions: {

      doOAUTH2Login: function doOAUTH2Login(context) {
        var _this4 = this;

        var model = this.get("controller.model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        var parameters_ok = true;

        console.log([_incusVastaanotaConfigEnvironment["default"], _incusVastaanotaConfigEnvironment["default"]]);

        if (_incusVastaanotaConfigEnvironment["default"].oAuthLoginURLS[context]) {
          if (context === "procountor") {
            var services = [];
            if (model.get("oauthContracts.procountor.send")) {
              services.push("SEND");
            }

            if (model.get("oauthContracts.procountor.receive")) {
              services.push("RECEIVE");
            }

            if (services.length > 0) {
              var parameters = services.join(";");
              var requestData = this._requestDataString("ProcountorAPI", parameters);
              var c = apixclient.setContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, requestData);

              _ember["default"].RSVP.Promise.resolve(c).then(function (data) {
                //Ember.RSVP.Promise.resolve(c).then((data)=>{
                apixclient.OAuthInit(userData, "procountor").then(function (data) {
                  var url = _incusVastaanotaConfigEnvironment["default"].oAuthLoginURLS[context];
                  url = url.replace("__STATE__", data.ApixAuthorizationCode);

                  window.location.replace(url);
                });
              })["catch"](function (err) {
                _this4.get("notify").error(_this4.get("i18n").t("common.error").toString() + ": Contract update failed: " + err);
              });
            } else {
              this.get("notify").error(this.get("i18n").t("common.error").toString() + ": no services selected.");
            }
          } else {
            window.location.replace(_incusVastaanotaConfigEnvironment["default"].oAuthLoginURLS[context]);
          }
        } else {
          this.get("notify").error(this.get("i18n").t("common.error").toString() + " oauth context is missing: " + context);
        }
      },

      UndoOAUTH2Login: function UndoOAUTH2Login(context) {
        var model = this.get("controller.model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        apixclient.deleteContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, "ProcountorAPI").then(function (data) {})["catch"](function () {});

        apixclient.UpdateCustomToken(userData, context + ":access_token", "", 1).then(function (data) {
          model.set("oauth." + context, false);
        })["catch"](function () {});

        apixclient.UpdateCustomToken(userData, context + ":refresh_token", "", 1).then(function (data) {})["catch"](function () {});
      },

      fileChanged: function fileChanged(file) {
        var model = this.get("controller.model");
        var reader = new FileReader();
        reader.onload = function () {
          model.set("imageURL", reader.result);
        };
        console.log(file);
        if ((file.type === "image/jpeg" || file.type === "image/png") && file.size <= 300000) {
          model.set("wrongFormat", false);
          reader.readAsDataURL(file);
        } else {
          model.set("wrongFormat", true);
          model.set("imageURL", "");
        }
      },

      doLowellDebtCollectionAcivationReques: function doLowellDebtCollectionAcivationReques() {
        var model = this.get("controller.model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        var requestData = this._requestDataString("LowellSEDebtCollection", "WAITING");
        var c = apixclient.setContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, requestData).then(function (data) {
          model.set("lowellDebtCollectionActive", "WAITING");
        });
      }
    },

    _requestDataString: function _requestDataString(contractType, parameters) {
      var doc = window.document.implementation.createDocument(null, "Request", null);
      var request = doc.firstChild;
      request.setAttribute("version", "1.0");
      var content = doc.createElement("Content");
      var group = doc.createElement("Group");

      group.appendChild(this._valueElement(doc, "ContractType", contractType));
      group.appendChild(this._valueElement(doc, "ContractStatus", "ACTIVE"));
      group.appendChild(this._valueElement(doc, "Parameters", parameters));

      content.appendChild(group);
      request.appendChild(content);
      var xs = new XMLSerializer();
      var header = '<?xml version="1.0" encoding="UTF-8" standalone="yes" ?>\n';
      //header = "";
      return header + xs.serializeToString(doc);
    },

    _valueElement: function _valueElement(doc, type, text) {
      var el = doc.createElement("Value");
      el.setAttribute("type", type);
      el.textContent = text;
      return el;
    },

    checkTokenStatus: function checkTokenStatus(model, context, identifier) {
      console.log("............................................checkTokenStatus ", context);
      //let model = this.get("controller.model");
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      if (!model.get("oauth")) {
        model.set("oauth", {});
      }

      var token_name = context;
      if (identifier) {
        token_name = context + ":" + identifier;
      }

      _ember["default"].RSVP.resolve(apixclient.CheckCustomToken(userData, token_name)).then(function (data) {
        model.set("oauth." + context, data.found);
      })["catch"](function (err) {
        model.set("oauth." + context, false);
        console.error(err);
      });
    }
  });
});