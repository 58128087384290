define('incus-vastaanota/router', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _incusVastaanotaConfigEnvironment['default'].locationType,
    session: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    willTransition: function willTransition(from, to, transition) {
      var possibleBlocks = ["list", "traffic", "archives", "invoice"];
      var target = transition.targetName.split(".");
      console.log(target);
      if (target[0] === "tools") {
        if (this.get("session").get("user")) {
          var contract = this.get("session").get("user")[0].ShowItems || false;
          if (contract) {
            if (!contract[target[0]]) {
              transition.abort();
            }
          }
        }
      }
      /*     if (possibleBlocks.includes(target[0])) {    
            if (this.get("session").get("user")) {
              let contract = this.get("session").get("user")[0].ShowItems || false;
              if (contract) {
                console.log(contract)
                if (target[0] === "invoice") {
                  target[0] = target[2];
                }
                contract.list = contract.invoices;
      
                if (!contract[target[0]]) {
                  transition.abort();
                }
              }
            }
          } */
    },

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');
      });
    }

  });

  Router.map(function () {

    //this.route('x');

    this.route('error', {
      path: '/error/:p1:p2:p3:p4'
    });
    this.route('invoice', function () {
      this.route('view', { path: '/invoice/view/:storageId' });
      this.route('create', function () {
        this.route('sell', function () {
          if (_incusVastaanotaConfigEnvironment['default'].build === "laskumappi") {
            this.route('upload');
          }
        });
        this.route('buy');
      });
    });

    this.route('storage', function () {
      this.route('view', { path: '/view/:storageId' });
    });

    this.route('archives', function () {
      this.route('received');
      this.route('sent');
    });

    this.route('traffic', function () {
      this.route('pitstop', function () {
        this.route('advertisement', { path: 'advertisement/:selected' });
        this.route('confirm', { path: 'confirm/:selected' });
        this.route('end', { path: 'end/:selected' });
        this.route('list');
        this.route('view');
      });

      this.route('sent');
      this.route('received');
    });

    this.route('oauth2', function () {
      this.route('procountor');
      this.route('test');
    });
    this.route('forgotpw', { path: 'resetpassword' });
    //  this.route('error');
    this.route('list');
    this.route('login');
    this.route('logout');

    this.route('tools', function () {
      this.route('addresses', function () {
        this.route('view');
      });
      // this.route('user');
      this.route('settings');
      this.route('users');
      this.route('admin');
      this.route('validation');
    });

    this.route('subusersettings');

    /*   this.resource('webshop', function() {
        this.route('index', {path:'/'})
        this.route('paymentcallback', {path:':type'})
      }) */

    /*     this.route('webshop', {path: '/webshop'});
        this.route('webshopreturn', {path: '/webshop/:type'}) */

    /*  this.route('registration', function() {
       this.route('form');
       this.route('info');
       this.route('receipt');
     });
     */
    this.route('terms');

    /* Test route for Country override */
    this.route('test', function () {
      this.route('sv'); // test
      this.route('fi'); // test
    });

    this.route('oauth2');

    this.route('pricing');
  });

  exports['default'] = Router;
});