define('incus-vastaanota/pods/archives/index/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),

    model: function model(params) {
      var model = _ember['default'].Object.extend({
        archiveURL: _incusVastaanotaConfigEnvironment['default'].archiveURL
      }).create();
      return model;
    }

  });
});
/* import Ember from 'ember';
import config from '../../../config/environment';

export default Ember.Route.extend({
  session: Ember.inject.service(),

  beforeModel: function(param) {
    let items = this.get("session").get("showItems")
    if (items && items.buy) {
      this.transitionTo("archives.received");
    } else if (items && items.sell) {
      this.transitionTo("archives.sent");
    }
  },
});
 */