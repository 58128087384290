define("incus-vastaanota/pods/tools/user/controller", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),
        i18n: _ember["default"].inject.service(),
        actions: {

            selectCountry: function selectCountry(evt) {
                var addressData = this.model.get("addresses");
                var addressToUpdate = addressData.find(function (addr) {
                    return addr.AddressType === evt.target.name;
                });
                _ember["default"].set(addressToUpdate, "Country", evt.target.value);
            },
            selectAddress: function selectAddress(evt) {
                this.get("model").set("selectedAddress", evt.target.value);
            },

            changeUserData: function changeUserData() {
                var _this = this;

                var i18n = this.get("i18n");
                var userData = this.get("session").get("user")[0];

                var newData = {
                    Name: this.get("model.name"),
                    AdditionalName: this.get("model.additionalName"),
                    WWW: this.get("model.www"),
                    PhoneNumber: this.get("model.phone"),
                    ContactPerson: this.get("model.contactPerson")
                };
                var addressData = this.get("model.addresses");

                var requestData = this._requestDataString(userData, newData, addressData);
                this.get("apixclient").changeUserData(userData, requestData).then(function (response) {
                    if (response.callStatus.success === "OK") {
                        (function () {
                            var user = _this.get("session.user");
                            Object.keys(newData).forEach(function (key) {
                                if (key === "WWW") {
                                    _ember["default"].set(user.objectAt(0), "Www", newData[key]);
                                } else if (key === "PhoneNumber") {
                                    _ember["default"].set(user.objectAt(0), "Phonenumber", newData[key]);
                                } else {
                                    _ember["default"].set(user.objectAt(0), key, newData[key]);
                                }
                            });
                            addressData.forEach(function (address) {
                                var isEmpty = true;
                                Object.keys(address).forEach(function (key) {
                                    if (address[key] && key !== "AddressType") {
                                        isEmpty = false;
                                    }
                                });
                                if (!isEmpty) {
                                    (function () {
                                        var ind = user.findIndex(function (data) {
                                            return data.hasOwnProperty("AddressType") && data.AddressType === address.AddressType;
                                        });
                                        Object.keys(address).forEach(function (key) {
                                            _ember["default"].set(user.objectAt(ind), key, address[key]);
                                        });
                                    })();
                                }
                            });
                            var session = _this.get("session.user");
                            window.localStorage.setItem("session", JSON.stringify(session));
                            _this.get("notify").info(i18n.t("settings.userInfo.updatedUser").toString());
                        })();
                    } else {
                        _this.get("notify").info(i18n.t("settings.userInfo.updateUserFailed").toString());
                    }
                })["catch"](function (error) {
                    console.log(error);
                    _this.get("notify").info(i18n.t("settings.userInfo.updateUserFailed").toString());
                });
                var mapping = {
                    categories: {
                        send: "Send",
                        receive: "Receive",
                        archive: "Archive"
                    },
                    send: {
                        duplicateCheck: "Dupcheck",
                        accounting: "Accountingcopy",
                        priority: "Priority",
                        consumerInvoicing: "B2Cinvoicing",
                        edi: "SendEDI",
                        debtCollect: "DebtCollect",
                        factoringCopy: "FactoringCopy"
                    },
                    receive: {
                        arrivalNotification: "InvoiceArrivalNotify",
                        expiryNotification: "InvoicePaymentExpiryNotify",
                        scanning: "Scanning",
                        emailScan: "EMailScan",
                        edi: "ReceiveEDI"
                    }
                };
                var categories = this.get("model.categories");
                var initialState = this.get("model.contractInitialState");

                var _loop = function (i) {
                    var parts = categories.objectAt(i).name.split(".");
                    if (initialState[parts[0]][parts[1]] !== categories.objectAt(i).field) {
                        (function () {
                            console.log(parts);

                            var contract = mapping[parts[0]][parts[1]];
                            console.log(contract);
                            var parameter = categories.objectAt(i).parameter || null;
                            /*                     if (parts[1] === "arrivalNotification" || parts[1] === "expiryNotification") {
                                                    parameter = categories.objectAt(i).parameter
                                                } */
                            if (categories.objectAt(i).field) {
                                _this.get("apixclient").setContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, _this._contractRequestDataString(contract, parameter)).then(function (response) {
                                    console.log(contract + " set");
                                    _ember["default"].set(initialState, categories.objectAt(i).name, true);
                                });
                            } else {
                                _this.get("apixclient").deleteContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, _this._contractRequestDataString(contract, parameter)).then(function (response) {
                                    console.log(contract + " removed");
                                    _ember["default"].set(initialState, categories.objectAt(i).name, false);
                                });
                            }
                        })();
                    }
                };

                for (var i = 0; i < categories.length; i++) {
                    _loop(i);
                }
            },

            changePassword: function changePassword() {
                var _this2 = this;

                var userData = this.get("session").get("user")[0];
                var i18n = this.get("i18n");
                var oldPass = this.get("model.oldPass");
                var repeatPass = this.get("model.repeatPass");
                var newPass = this.get("model.newPass");
                if (newPass !== repeatPass) {
                    this.get("notify").info(i18n.t("settings.userInfo.updatePasswordsNomatch").toString());
                    return;
                }
                var callback = function callback(feedback) {
                    switch (feedback) {
                        case "success":
                            _this2.get("notify").info(i18n.t("settings.userInfo.updatedPassword").toString());
                            break;
                        case "fail":
                            _this2.get("notify").info(i18n.t("settings.userInfo.updatePasswordFailed").toString());
                            break;
                        case "wrongPass":
                            _this2.get("notify").info(i18n.t("settings.userInfo.updateWrongPass").toString());
                    }
                };
                this.get("apixclient").changePassword(userData, oldPass, newPass, callback);
            }
        },
        _contractRequestDataString: function _contractRequestDataString(contractType, parameters) {
            var doc = window.document.implementation.createDocument(null, "Request", null);
            var request = doc.firstChild;
            request.setAttribute("version", "1.0");
            var content = doc.createElement("Content");
            var group = doc.createElement("Group");

            group.appendChild(this._valueElement(doc, "ContractType", contractType));
            group.appendChild(this._valueElement(doc, "ContractStatus", "ACTIVE"));
            group.appendChild(this._valueElement(doc, "Parameters", parameters));

            content.appendChild(group);
            request.appendChild(content);
            var xs = new XMLSerializer();
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
            //header = "";
            return header + xs.serializeToString(doc);
        },
        _requestDataString: function _requestDataString(userData, data, addresses) {
            var _this3 = this;

            var doc = window.document.implementation.createDocument(null, "Request", null);
            var request = doc.firstChild;
            request.setAttribute("version", "1.0");
            var content = doc.createElement("Content");
            var group = doc.createElement("Group");

            Object.keys(data).forEach(function (key) {
                console.log(key);

                group.appendChild(_this3._valueElement(doc, key, data[key]));
            });
            group.appendChild(this._valueElement(doc, "y-tunnus", userData.Ytunnus));
            group.appendChild(this._valueElement(doc, "Email", userData.Email));
            group.appendChild(this._valueElement(doc, "Language", userData.LanguageCoded));
            content.appendChild(group);

            addresses.forEach(function (address) {
                var isEmpty = true;
                Object.keys(address).forEach(function (key) {
                    if (address[key] && key !== "AddressType") {
                        isEmpty = false;
                    }
                });
                if (!isEmpty) {
                    group = doc.createElement("Group");
                    Object.keys(address).forEach(function (key) {
                        group.appendChild(_this3._valueElement(doc, key, address[key]));
                    });
                    content.appendChild(group);
                }
            });
            request.appendChild(content);
            var xs = new XMLSerializer();
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
            //header = "";
            console.log(header + xs.serializeToString(doc));
            return header + xs.serializeToString(doc);
        },
        _valueElement: function _valueElement(doc, type, text) {
            var el = doc.createElement("Value");
            el.setAttribute("type", type);
            el.textContent = text;
            return el;
        }
    });
});