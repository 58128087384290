define('incus-vastaanota/initializers/session', ['exports', 'ember', 'incus-vastaanota/services/countryselector'], function (exports, _ember, _incusVastaanotaServicesCountryselector) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var beforeModel = function beforeModel(transition) {
    this._super(transition);
    _ember['default'].Logger.log(transition.targetName);

    if (transition.targetName !== 'index' && transition.targetName !== 'logout' && transition.targetName !== 'login' && transition.targetName !== 'invoice.view' && transition.targetName !== 'storage.view' && transition.targetName !== 'registration.form' && transition.targetName !== 'registration.index' && transition.targetName !== 'registration.info' && transition.targetName !== 'registration.receipt' && transition.targetName !== 'terms' && transition.targetName !== 'pricing' && transition.targetName !== 'forgotpw' && transition.targetName !== 'traffic.pitstop.view' && !this.get("session.authenticated")) {
      _ember['default'].Logger.log("Missing a session for", transition.targetName);
      transition.abort();
      // let params = transition.params[transition.targetName]
      this.set("session.redirection", { queryParams: _extends({}, transition.queryParams), destination: transition.targetName, path: transition.params[transition.targetName] });
      this.transitionTo("login");
    }
  };

  exports['default'] = {
    initialize: function initialize(container, app) {
      app.register("session:service", _ember['default'].inject.service(), {
        instantiate: false
      });

      app.register("session:beforeModel", beforeModel, {
        instantiate: false
      });
      app.inject("route", "beforeModel", "session:beforeModel");
      app.inject("route", "session", "session:service");
      app.inject("controller", "session", "session:service");

      // Inject countryselector to beforeModel
      app.register("session:countryselector", _ember['default'].inject.service(), { instantiate: false });
      app.inject("route", "countryselector", "session:countryselector");
    }
  };
});