define("incus-vastaanota/pods/traffic/pitstop/list/route", ["exports", "ember", "incus-vastaanota/config/environment", "incus-vastaanota/models/traffic", "incus-vastaanota/utils/list-utils", "incus-vastaanota/utils/demo", "ember-cli-pagination/computed/paged-array"], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsTraffic, _incusVastaanotaUtilsListUtils, _incusVastaanotaUtilsDemo, _emberCliPaginationComputedPagedArray) {
  exports["default"] = _ember["default"].Route.extend({
    apixclient: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var countryselector = this.get("countryselector");

      var locale = _incusVastaanotaConfigEnvironment["default"].defaultLocale;
      if (window.localStorage) {
        locale = window.localStorage.getItem("locale");
      }

      return apixclient.pitstopTraffic(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, 1000).then(function (data) {
        var invoiceList = _incusVastaanotaUtilsListUtils["default"].processTrafficData(data, apixclient, userData, function () {
          return _incusVastaanotaModelsTraffic["default"].create();
        });
        _incusVastaanotaUtilsListUtils["default"].processTrafficLightQuery(invoiceList, apixclient, userData, "pitstop");

        var model = _ember["default"].Object.extend({
          fields: [{
            fieldName: "picture",
            translationName: "picture",
            numeric: false,
            sortable: false
          }, {
            fieldName: "data",
            translationName: "data",
            numeric: false,
            sortable: false
          }, {
            fieldName: "senderName",
            translationName: "recipient",
            numeric: false,
            sortable: true
          }, {
            fieldName: "senderName",
            translationName: "sender",
            numeric: false,
            sortable: true
          }, {
            fieldName: "documentID",
            translationName: "invoiceNumber",
            numeric: true,
            sortable: true
          }, {
            fieldName: "createTime",
            translationName: "receivedDate",
            numeric: false,
            sortable: true
          }, {
            fieldName: "dueDate",
            translationName: "dueDate",
            numeric: false,
            sortable: true
          }, {
            fieldName: "sum",
            translationName: "sum",
            numeric: false,
            sortable: true
          }, {
            fieldName: "expireTime",
            translationName: "expiresDate",
            numeric: false,
            sortable: true
          },

          /*
          {
          fieldName: 'messageType',
          numeric: false,
          sortable:true,
          },
          */

          {
            fieldName: "deliveryMethod",
            translationName: "deliveryMethod",
            numeric: false,
            sortable: true
          }, {
            fieldName: "pitstopStatus",
            translationName: "pitstopStatus",
            numeric: false,
            sortable: true
          }, {
            fieldName: "pitstopTimeleft",
            translationName: "pitstopTimeleft",
            numeric: false,
            sortable: false
          }, {
            fieldName: "pitstopDelete",
            translationName: "pitstopDelete",
            numeric: false,
            sortable: false
          }],

          page: 1,
          perPage: 100,
          isDemo: _incusVastaanotaUtilsDemo["default"].check_env(),

          selectedInvoices: 0,
          factoringState: _ember["default"].computed("selectedInvoices", function () {
            var si = this.get("selectedInvoices");
            return si <= 0;
          }),
          countryselector: countryselector,
          pagedContent: (0, _emberCliPaginationComputedPagedArray["default"])("invoiceListDynamic", {
            pageBinding: "page",
            perPageBinding: "perPage"
          }),
          totalPagesBinding: "pagedContent.totalPages",

          sortProperty: "sentTime",
          sortDirection: false,
          sortNumeric: false,

          queryDone: true,

          // Used for filtering the resultset by date
          startDate: moment().subtract(30, "day").toDate(),
          endDate: new Date(),
          dueDate: "",
          receiverFilter: "",
          documentIDFilter: "",
          freeText: "",
          locale: locale,

          invoiceListDynamic: _ember["default"].computed("sortProperty", "sortDirection", "sortNumeric", "receiverFilter", "invoiceList.[]", function () {
            var list = this.get("invoiceList");
            var receiverFilter = this.get("receiverFilter");
            var invoiceArray = list.filter(function (invoice) {
              var includeInvoice = true;
              if (receiverFilter) {
                _ember["default"].Logger.log(receiverFilter);
                var re = new RegExp(receiverFilter, "i");
                if (!re.test(invoice.receiverName)) {
                  includeInvoice = false;
                }
              }
              return includeInvoice;
            });

            var sortProperty = this.get("sortProperty");
            var sortNumeric = this.get("sortNumeric");
            var sortDirection = this.get("sortDirection");
            if (sortProperty) {
              invoiceArray.sort(_incusVastaanotaUtilsListUtils["default"].dynamicSort(sortProperty, sortDirection, sortNumeric));
            }

            console.log("Sorted by", sortProperty);

            this.set("queryDone", true);
            this.set("resultCount", invoiceArray.length);
            return invoiceArray;
          })
        }).create({
          invoiceList: invoiceList
        });

        return model;
      });
    },

    actions: {
      setSortProperty: function setSortProperty(sortProperty, numeric, sortable) {
        if (!sortable) {
          return;
        }
        var currentSort = this.get("controller.model.sortProperty");
        if (currentSort === sortProperty) {
          var sortDirection = this.get("controller.model.sortDirection");
          this.set("controller.model.sortDirection", !sortDirection);
        } else {
          this.set("controller.model.sortDirection", false);
        }
        if (numeric) {
          this.set("controller.model.sortNumeric", true);
        } else {
          this.set("controller.model.sortNumeric", false);
        }
        this.set("controller.model.sortProperty", sortProperty);
      },

      clearAllDateFilters: function clearAllDateFilters() {
        this.set("controller.model.startDate", moment().subtract(30, "day").toDate());
        this.set("controller.model.endDate", new Date());
        this.set("controller.model.dueDate", "");
        this.set("controller.model.receiverFilter", "");
        this.set("controller.model.documentIDFilter", "");
        this.set("controller.model.freeText", "");
      },

      getMetadata: function getMetadata(item) {
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        var showDetails = item.get("showDetails");
        var meta = item.get("meta");

        if (showDetails === true) {
          item.set("showDetails", false);
          return;
        } else if (meta) {
          item.set("showDetails", true);
          return;
        }

        apixclient.getMetadata(userData.TransferID, item.senderArchiveId, userData.TransferKey).then(function (response) {
          var data = response.data[0];
          item.set("meta", data);
          item.set("showDetails", true);
        });
      },

      removeItem: function removeItem(item) {
        var _this = this;

        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];
        var i18n = this.get("i18n");
        var list = this.get("controller.model.invoiceList");
        var confirmationText = i18n.t("traffic.tables.pitstopDeleteConfirmation").toString();
        var r = window.confirm(confirmationText + " " + item.documentID + "?");
        if (r) {
          apixclient.pitstopDelete(item.uniqueMessageID, userData.TransferID, userData.TransferKey).then(function (response) {
            var data = response.data[0];
            if (data.Count === "1") {
              var invoiceArray = list.filter(function (invoice) {
                return invoice.uniqueMessageID !== item.uniqueMessageID;
              });
              _this.set("controller.model.invoiceList", invoiceArray);
            }
          });

          this._getItemSentTrafficValue(apixclient, userData, item.uniqueMessageID).then(function (ehItem) {
            console.log(["_getItemSentTrafficValue", ehItem, userData]);
            //apixclient.pitstopRemoveDupcheck(item, uniqueCompanyId, businessId, transferId, transferKey)
            apixclient.removeDupcheck(item, userData.UniqueCompanyID, ehItem.senderId, userData.TransferID, userData.TransferKey);
          });
        }
      },

      handleSelection: function handleSelection(item) {
        var model = this.get("controller.model");

        if (!item.get("selected")) {
          item.set("selected", true);

          var selected = model.get("selectedInvoices");
          model.set("selectedInvoices", ++selected);
        } else {
          item.set("selected", false);

          var selected = model.get("selectedInvoices");
          model.set("selectedInvoices", --selected);
        }
      }

    },

    _getItemSentTrafficValue: function _getItemSentTrafficValue(apixclient, userData, uniqueMessageId) {
      return apixclient.sentTraffic(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, 1000).then(function (data) {
        for (var i = 0; i < data.data.length; i++) {
          var entry = data.data[i];
          if (entry.uniqueMessageId == uniqueMessageId) {
            console.log(["_getItemSentTrafficValue found value", entry]);

            return entry;
          }
        }
      });
    }
  });
});