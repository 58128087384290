define("incus-vastaanota/pods/index/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),

    beforeModel: function beforeModel() {
      if (window.location.href.includes("fl")) {
        this.transitionTo("invoice.create.sell");
      } else {
        this.transitionTo("traffic.sent");
      }
    }
  });
});