define('incus-vastaanota/models/traffic', ['exports', 'ember', 'incus-vastaanota/utils/email'], function (exports, _ember, _incusVastaanotaUtilsEmail) {
  exports['default'] = _ember['default'].Object.extend({
    /* expire timer */
    timeLeft: 0,
    _interval: null,
    notExpired: true,
    timer: _ember['default'].computed('timeLeft', function () {
      var that = this;
      if (this._interval === null && this.get("createTime")) {
        this._interval = window.setInterval(function () {
          var minutes = timeLeftInMinutes(that.get("expireTime"));
          if (minutes === 0) {
            that.set('notExpired', false);
          }
          that.set('timeLeft', minutes + " min ");
        }, 1000);
      }

      return this.get('timeLeft');
    }),

    setFields: function setFields(data) {

      /*
      let mapping = {
        "invoiceNumber": "documentId",
        "exitTime": "sentDate",
        "recipient": "receiver",
        "sender": "sender",
        "type": "messageType",
        "attachments": "attachments",
        "ackStatus": "acknowledgment",
      };
      */

      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          this.set(key, data[key]);

          //console.log([key,data[key]]);
        }
      }

      //console.log(data);

      if (!this.get("type") && this.get("deliveryMethod")) {
        this.set("type", this.get("deliveryMethod"));
      }

      this.set("ackStatus", this.normalize_ack(this.get("ackStatus")));
      this.set("messageType", this.get_type(this.get("channelInName"), this.get("type")));
      this.set("deliveryMethod", this.get_deliverymethod(this.get("channelInName"), this.get("type")));
      this.set("timeLeft", timeLeftInMinutes(this.get("expireTime")) + " min");
      this.set("timer", this.get("timer"));
      // for simple _generateDownloadLink func use
      //      this.set("storageID", this.get("senderArchiveId"));
      //      this.set("storageID", this.get("senderArchiveId"));

      this.set("pitstopStatus", this.get_pitstopstatus(data));
    },

    normalize_ack: function normalize_ack(ack_status) {

      if (_ember['default'].$.inArray(ack_status, ["POS", "NEG"]) === -1) {
        console.log("Ack status unknown:", ack_status);
        ack_status = "";
      }

      return ack_status;
    },

    get_type: function get_type(channelInName, type) {
      if (_ember['default'].isPresent(channelInName) && channelInName.match(/[Ss]canned|[Ss]canning/) || type === 'Paper') {
        return "billOrSomething";
      } else if (type === 'eInvoice' || type === 'email' || type === 'e-lasku' || type === "Suoramaksu" || type === 'emailscan') {
        return "bill";
      } else if (type === 'ack') {
        return "acknowledgment";
      } else if (type === 'Netpost') {
        return "billOrPayslip";
      } else if (type === 'STICKERS') {
        return "sticker";
      } else if (type === 'factoring') {
        return "factoring";
      } else if (type === 'EDI' || type === 'IMAGE') {
        return "edi";
      } else if (type === 'archive') {
        return "archive";
      }

      return "unknown";
    },

    get_deliverymethod: function get_deliverymethod(channelInName, type) {
      if (_ember['default'].isPresent(channelInName) && channelInName.match(/[Ss]canned|[Ss]canning/)) {
        return "scanned";
      } else if (type === 'eInvoice') {
        return "webBill";
      } else if (type === 'Paper') {
        return "letter";
      } else if (type === 'EDI' || type === 'IMAGE') {
        return "edi";
      } else if (type === 'email') {
        return "email";
      } else if (type === 'e-lasku') {
        return "eBill";
      } else if (type === 'Suoramaksu') {
        return "directPayment";
      } else if (type === 'Netpost') {
        return "webPost";
      } else if (type === 'Netposti') {
        return "webPost";
      } else if (type === 'factoring') {
        return "webBill";
      }

      return "unknown";
    },

    get_pitstopstatus: function get_pitstopstatus(item) {

      if (!item.startTime) {
        return "waiting";
      } else if (item.routeUpdateTime) {
        return "send_to_factoring";
      } else if (!item.signedTime) {
        return "waiting_signedtime";
      } else if (!item.financierAgreeTime) {
        return "waiting_financieragreetime";
      } else {
        return "waiting_buyeracceptance";
      }
    },

    /* CopyPaste from incus-laskumappi models/invoice.js */

    sumHuman: (function () {
      console.log("sumHuman");
      return parseFloat(this.get("sum")).toFixed(2).replace(".", ",");
    }).property("sum"),

    createTimeHuman: (function () {
      var value = parseTime(this.get("createTime"), true);
      return formatTime(value, true);
    }).property("createTime"),

    expireTimeHuman: (function () {
      var value = parseTime(this.get("expireTime"), true);
      return formatTime(value, true);
    }).property("expireTime"),

    dueDateHuman: (function () {
      var value = parseTime(this.get("dueDate"), false);
      return formatTime(value, false);
    }).property("dueDate"),

    startTimeHuman: (function () {
      var value = parseTime(this.get("startTime"), true);
      return formatTime(value, false);
    }).property("startTime"),

    daysBeforeDue: (function () {
      var dueDate = moment(this.get("dueDate"), "YYYY-MM-DD");
      var now;
      if (this.get("startTime")) {
        /* Database is in UTC. */
        now = moment.utc(this.get("startTime"), "YYYY-MM-DD HH:mm:ss").local();
      } else {
        now = moment();
      }
      /* Drop HMS values because they actually influence the way Moment calculates day differences. */
      now = moment(now.format("YYYY-MM-DD"), "YYYY-MM-DD");

      /* later.diff(earlier) >= 0 */
      return dueDate.diff(now, "days");
    }).property("dueDate", "startTime", "startTimeGuess"),

    provision: (function () {
      var toDueDateDays = this.get("daysBeforeDue");
      var pct;
      if (toDueDateDays >= 46) {
        pct = 4;
      } else if (toDueDateDays >= 31) {
        pct = 3.5;
      } else if (toDueDateDays >= 15) {
        pct = 3;
      } else {
        pct = 2;
      }

      var provision = parseFloat(this.get("sum")) * pct / 100;
      /* 0.002 is 0.2 * ULP and ensures rounding towards positive infinity. */
      return Math.round(100 * Math.max(provision + 0.002, 100)) / 100;
    }).property("sum", "daysBeforeDue"),

    provisionHuman: (function () {
      var value = this.get("provision");
      return value.toFixed(2).replace(".", ",");
    }).property("provision"),

    sumWithoutProvision: (function () {
      var provision = this.get("provision");
      var sum = parseFloat(this.get("sum"));
      return sum - provision;
    }).property("provision", "sum"),

    sumWithoutProvisionHuman: (function () {
      var value = this.get("sumWithoutProvision");
      return value.toFixed(2).replace(".", ",");
    }).property("sumWithoutProvision"),

    /* Vaidity calculators */
    receiverCountryCodeValid: (function () {
      var rCC = this.get("receiverCountryCode");
      console.log("Determining country code validity: " + rCC);
      return rCC === "FI";
    }).property("receiverCountryCode"),

    dueDateValid: (function () {
      return this.get("daysBeforeDue") < 61;
    }).property("dueDate"),

    sumValid: (function () {
      var sum = parseFloat(this.get("sum"));
      return sum >= 2000.00;
    }).property("sum"),

    valid: (function () {
      return !this.get("startTime") && this.get("receiverCountryCodeValid") && this.get("dueDateValid") && this.get("sumValid");
    }).property("startTime", "receiverCountryCodeValid", "dueDateValid", "sumValid"),

    senderCombined: function senderCombined() {
      return this.get("sender") + " / " + this.get("senderId");
    },

    receiverCombined: function receiverCombined() {
      return this.get("recipient") + " / " + this.get("receiverId");
    }

  });

  /* Parse iso8601-style timestamp, return it in UTC */
  var parseTime = function parseTime(value, includeTime) {
    if (!value) {
      return null;
    }
    var result = [0, 0, 0, 0, 0, 0];
    var date = value.match(/^(\d{4})-(\d\d)-(\d\d)/);
    result[0] = date[1] - 0;
    result[1] = date[2] - 1;
    result[2] = date[3] - 0;
    if (includeTime) {
      var time = value.match(/(\d\d):(\d\d):(\d\d)[.\d]*$/);
      result[3] = time[1] - 0;
      result[4] = time[2] - 0;
      result[5] = time[3] - 0;
    }
    return new Date(result[0], result[1], result[2], result[3], result[4], result[5]);
  };

  var twod = function twod(v) {
    return v < 10 ? "0" + v : "" + v;
  };

  var timeLeftInMinutes = function timeLeftInMinutes(expireTime) {
    var date = new Date().toISOString();
    date = date.replace("Z", "").replace("T", " ");
    var time = parseTime(date, true);
    var diff = parseTime(expireTime, true) - time;
    var minutes = Math.floor(diff / 1000 / 60) + 1;
    return minutes;
  };

  /* format date and time for human consumption in local time (of browser) */
  var formatTime = function formatTime(value, includeTime) {
    if (!value) {
      return "";
    }
    value = new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000);
    return twod(value.getDate()) + "." + twod(value.getMonth() + 1) + "." + value.getFullYear() + (includeTime ? " " + twod(value.getHours()) + ":" + twod(value.getMinutes()) : "");
  };
});