define("incus-vastaanota/pods/subusersettings/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    apixclient: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    model: function model() {
      var model = _ember["default"].Object.extend({
        i18n: this.get("i18n"),
        countryselector: this.get("countryselector"),
        localeObserver: _ember["default"].observer("i18n.locale", function () {
          var locale = this.get("i18n.locale");
          _ember["default"].Logger.log("Setting locale to", locale);
          if (!window.localStorageAlias) {
            window.localStorageAlias = window.localStorage;
            if (!window.localStorage) {
              window.localStorageAlias = {};
              window.localStorageAlias.removeItem = function () {};
              window.localStorageAlias.getItem = function (item) {
                return this[item];
              };
              window.localStorageAlias.setItem = function (item, value) {
                this[item] = value;
              };
            }
          }
          window.localStorageAlias.setItem("locale", locale);
        })
      }).create();

      return model;
    }
  });
});