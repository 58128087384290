define("incus-vastaanota/pods/components/admin-user-selector/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 6
            }
          },
          "moduleName": "incus-vastaanota/pods/components/admin-user-selector/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'title');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "title", ["subexpr", "str-concat", [["subexpr", "str-concat", [["get", "u.Name", ["loc", [null, [15, 68], [15, 74]]]], " "], [], ["loc", [null, [15, 55], [15, 79]]]], ["get", "u.y-tunnus", ["loc", [null, [15, 80], [15, 90]]]]], [], ["loc", [null, [15, 42], [15, 92]]]]], ["element", "action", ["switchUser", ["get", "u", ["loc", [null, [15, 32], [15, 33]]]]], [], ["loc", [null, [15, 10], [15, 35]]]], ["content", "u.Name", ["loc", [null, [15, 93], [15, 103]]]]],
        locals: ["u"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 3
            },
            "end": {
              "line": 23,
              "column": 3
            }
          },
          "moduleName": "incus-vastaanota/pods/components/admin-user-selector/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("   Saldo: ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "session.saldo", ["loc", [null, [22, 10], [22, 28]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 6
          }
        },
        "moduleName": "incus-vastaanota/pods/components/admin-user-selector/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "admin-selector");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "admin-selector-current-user");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-chevron-circle-down");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "admin-selector-search");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "search-icon");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-search");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "admin-selector-list");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "admin-saldo");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("   ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element2, 'title');
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createMorphAt(element2, 0, 0);
        morphs[3] = dom.createAttrMorph(element3, 'class');
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "title", ["subexpr", "str-concat", [["subexpr", "str-concat", [["get", "currentUser.Name", ["loc", [null, [3, 80], [3, 96]]]], " "], [], ["loc", [null, [3, 67], [3, 101]]]], ["get", "currentUser.y-tunnus", ["loc", [null, [3, 102], [3, 122]]]]], [], ["loc", [null, [3, 54], [3, 124]]]]], ["element", "action", ["clickView"], ["bubbles", false], ["loc", [null, [3, 9], [3, 47]]]], ["content", "currentUser.Name", ["loc", [null, [3, 161], [3, 181]]]], ["attribute", "class", ["subexpr", "if", [["get", "visible", ["loc", [null, [5, 31], [5, 38]]]], "display-list", "hide-list"], [], []]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "search", ["loc", [null, [7, 31], [7, 37]]]]], [], []], "placeholder", ["subexpr", "t", ["common.adminSearch"], [], ["loc", [null, [7, 50], [7, 74]]]]], ["loc", [null, [7, 4], [7, 76]]]], ["block", "each", [["get", "filteredUsers", ["loc", [null, [14, 14], [14, 27]]]]], [], 0, null, ["loc", [null, [14, 6], [16, 15]]]], ["block", "unless", [["get", "session.isInvoicingCustomer", ["loc", [null, [21, 13], [21, 40]]]]], [], 1, null, ["loc", [null, [21, 3], [23, 14]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});