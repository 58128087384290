define("incus-vastaanota/pods/forgotpw/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "position:relative;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "center_div registration");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "registration-button registration-button-button");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element6, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
          morphs[4] = dom.createElementMorph(element7);
          morphs[5] = dom.createMorphAt(element7, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordsValid", []], "passwords-valid"], [], []], " ", "center_div"]]], ["inline", "t", ["common.username"], [], ["loc", [null, [5, 15], [5, 38]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.username", ["loc", [null, [6, 20], [6, 34]]]]], [], []], "placeholder", ["subexpr", "t", ["common.username"], [], ["loc", [null, [6, 47], [6, 68]]]]], ["loc", [null, [6, 6], [6, 70]]]], ["inline", "t", ["passwordreset.description"], [], ["loc", [null, [7, 9], [7, 42]]]], ["element", "action", ["requestPWRenewal"], [], ["loc", [null, [10, 69], [10, 98]]]], ["inline", "t", ["passwordreset.send"], [], ["loc", [null, [10, 99], [10, 126]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 40,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1, "autocomplete", "off");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "position:relative;");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "valid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-check");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "invalid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-times");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "position:relative;");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "valid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-check");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "invalid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-times");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "center_div registration");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "registration-button registration-button-button");
            dom.setAttribute(el3, "type", "submit");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element1, [3]);
            var element5 = dom.childAt(element1, [5, 1]);
            var morphs = new Array(13);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createAttrMorph(element2, 'name');
            morphs[2] = dom.createAttrMorph(element2, 'autocomplete');
            morphs[3] = dom.createMorphAt(element3, 0, 0);
            morphs[4] = dom.createMorphAt(element3, 2, 2);
            morphs[5] = dom.createMorphAt(element2, 3, 3);
            morphs[6] = dom.createAttrMorph(element4, 'class');
            morphs[7] = dom.createAttrMorph(element4, 'name');
            morphs[8] = dom.createAttrMorph(element4, 'autocomplete');
            morphs[9] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
            morphs[10] = dom.createMorphAt(element4, 3, 3);
            morphs[11] = dom.createElementMorph(element5);
            morphs[12] = dom.createMorphAt(element5, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordsValid", []], "passwords-valid"], [], []], " ", "center_div"]]], ["attribute", "name", ["get", "new-password", []]], ["attribute", "autocomplete", ["get", "off", []]], ["inline", "t", ["settings.userInfo.newPass"], [], ["loc", [null, [17, 15], [17, 48]]]], ["inline", "t", ["passwordreset.min4chars"], [], ["loc", [null, [17, 54], [17, 87]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "model.newPass", ["loc", [null, [18, 36], [18, 49]]]]], [], []], "placeholder", ["subexpr", "t", ["settings.userInfo.newPass"], [], ["loc", [null, [18, 62], [18, 93]]]]], ["loc", [null, [18, 6], [18, 95]]]], ["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordsValid", []], "passwords-valid"], [], []], " ", "center_div"]]], ["attribute", "name", ["get", "repeat-new-password", []]], ["attribute", "autocomplete", ["get", "off", []]], ["inline", "t", ["settings.userInfo.repeatPass"], [], ["loc", [null, [27, 15], [27, 51]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "model.repeatPass", ["loc", [null, [28, 36], [28, 52]]]]], [], []], "placeholder", ["subexpr", "t", ["settings.userInfo.repeatPass"], [], ["loc", [null, [28, 65], [28, 99]]]]], ["loc", [null, [28, 6], [28, 101]]]], ["element", "action", ["changePassword"], [], ["loc", [null, [37, 69], [37, 96]]]], ["inline", "t", ["settings.userInfo.changePassword"], [], ["loc", [null, [37, 111], [37, 153]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 4
              },
              "end": {
                "line": 42,
                "column": 89
              }
            },
            "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "invalid-text");
            dom.setAttribute(el1, "style", "color:red");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "new-link");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["passwordreset.invalidlink"], [], ["loc", [null, [41, 46], [41, 80]]]], ["element", "action", ["resetState"], [], ["loc", [null, [42, 26], [42, 49]]]], ["inline", "t", ["passwordreset.here"], [], ["loc", [null, [42, 50], [42, 76]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 43,
              "column": 2
            }
          },
          "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.validRequest", ["loc", [null, [14, 8], [14, 26]]]]], [], 0, 1, ["loc", [null, [14, 2], [42, 96]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 46,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "forgotpw-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pwForm");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element8, 1, 1);
        morphs[1] = dom.createMorphAt(element8, 2, 2);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.requesting", ["loc", [null, [3, 8], [3, 24]]]]], [], 0, null, ["loc", [null, [3, 2], [12, 9]]]], ["block", "unless", [["get", "model.requesting", ["loc", [null, [13, 12], [13, 28]]]]], [], 1, null, ["loc", [null, [13, 2], [43, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});