define("incus-vastaanota/pods/components/contract-checkbox/component", ["exports", "ember", "incus-vastaanota/utils/email"], function (exports, _ember, _incusVastaanotaUtilsEmail) {
  exports["default"] = _ember["default"].Component.extend({

    previousState: null,
    checked: null,
    name: "",
    hasEditableParameter: false,
    parameter: "",
    changeClass: "",
    parameterValid: "",
    // indentation: true,
    valueChanged: _ember["default"].observer('checked', 'previousState', function () {
      var prev = this.get("previousState");
      var curr = this.get("checked");
      if (prev === curr) {
        this.set("changeClass", '');
      } else if (prev && !curr) {
        this.set("changeClass", 'to-negative');
      } else {
        this.set("changeClass", 'positive');
      }
    }),
    emailObserver: _ember["default"].observer('parameter', 'checked', function () {
      if (this.get("hasEditableParameter")) {
        var parameter = this.get("parameter");
        if (!this.get("checked")) {
          this.set("parameterValid", "");
        } else if (this.get("checked") && parameter && _incusVastaanotaUtilsEmail["default"].validateEmail(parameter)) {
          this.set("parameterValid", "is-valid");
        } else {
          this.set("parameterValid", "is-invalid");
        }
      } else {
        return null;
      }
    }),
    indentation: _ember["default"].computed('name', function () {
      var parts = this.get("name").split(".");
      if (parts[0] === 'categories') {
        return false;
      }
      return true;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      ok: function ok() {
        this.sendAction('action');
      },
      toggleShow: function toggleShow() {
        this.toggleProperty("enabled");
      }
    }
  });
});