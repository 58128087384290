define('incus-vastaanota/pods/login/controller', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Controller.extend({
    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),
    queryParams: ['from', 'transition', 'destination'],
    from: null,
    route: null,

    actions: {
      login: function login() {
        var _this = this;

        var username = this.get("username");
        var password = this.get("password");
        var app = _incusVastaanotaConfigEnvironment['default'].build;
        this.get("apixclient").login(username, password, app).then(function (data) {
          if (!data) {
            _this.get('notify').error(_this.get('i18n').t("session.loginError").toString());
            return;
          }
          _this.get("session").login(data, username, password).then(function (route) {
            var redirection = _this.get("session.redirection");
            if (_this.get("model.infoText") === "webshop") {
              _this.transitionToRoute("webshop");
            } else if (redirection) {
              var args = [];
              args.push(redirection.destination);
              if (Object.keys(redirection.path).length > 0) {
                args.push(redirection.path[Object.keys(redirection.path)[0]]);
              }
              if (Object.keys(redirection.queryParams).length > 0) {
                args.push({ queryParams: _extends({}, redirection.queryParams) });
              }
              _this.transitionToRoute.apply(_this, args);
            } else {
              _this.transitionToRoute(route);
            }
            _this.get("saldoupdater").update_saldo(_this);
          })['catch'](function () {
            _this.get('notify').error(_this.get('i18n').t("session.loginError").toString());
            return;
          });
        })['catch'](function (data) {
          if (data === "500") {
            _this.get('notify').error("Internal server error");
          } else {
            _ember['default'].Logger.log("login error");
            _ember['default'].Logger.log(data);
            _this.get('notify').error(_this.get('i18n').t("session.forgotPassword", username, data).toString());
          }
        })['finally'](function () {});
      },
      setLang: function setLang(lang) {
        _ember['default'].Logger.log(lang);
        this.set("i18n.locale", lang);
        if (!window.localStorageAlias) {
          window.localStorageAlias = window.localStorage;
          if (!window.localStorage) {
            window.localStorageAlias = {};
            window.localStorageAlias.removeItem = function () {};
            window.localStorageAlias.getItem = function (item) {
              return this[item];
            };
            window.localStorageAlias.setItem = function (item, value) {
              this[item] = value;
            };
          }
        }
        window.localStorageAlias.setItem('locale', lang);
      },
      registration: function registration() {
        // window.open( this.get("model.registrationUrl") );
        this.transitionTo('registration.index');
      },

      forgotPW: function forgotPW() {
        this.transitionTo('forgotpw', { queryParams: null });
        //window.open( this.get("model.passwordChangeUrl") );
      }

    }
  });
});