define('incus-vastaanota/pods/components/admin-user-selector/component', ['exports', 'ember', 'incus-vastaanota/utils/email', 'incus-vastaanota/app', 'incus-vastaanota/pods/mixins/clickoutside'], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaApp, _incusVastaanotaPodsMixinsClickoutside) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend(_incusVastaanotaPodsMixinsClickoutside['default'], {
    session: _ember['default'].inject.service(),
    users: [],
    filterUsers: [],
    visible: false,
    user: null,
    search: "",
    init: function init() {
      this._super.apply(this, arguments);
      var users = [].concat(_toConsumableArray(this.get("users")));
      users.sort(function (a, b) {
        return a.Name.localeCompare(b.Name);
      });
      this.set("filteredUsers", users);
    },
    onOutsideClick: function onOutsideClick() {
      this.set("visible", false);
    },
    currentUser: _ember['default'].computed('user', function () {
      var _this = this;

      return this.get("users").find(function (u) {
        return _this.get("user") === u.UniqueID;
      });
    }),
    usersObserver: _ember['default'].observer("users.@each", function () {
      _ember['default'].run.debounce(this, this.filterUsers, 500);
    }),
    searchObserver: _ember['default'].observer("search", "users", function () {
      _ember['default'].run.debounce(this, this.filterUsers, 500);
    }),
    filterUsers: function filterUsers() {
      var _this2 = this;

      var users = this.get("users").filter(function (u) {
        return u.Name.toLowerCase().includes(_this2.get("search").toLowerCase()) || u["y-tunnus"].toLowerCase().includes(_this2.get("search").toLowerCase());
      });
      users.sort(function (a, b) {
        return a.Name.localeCompare(b.Name);
      });
      this.set("filteredUsers", users);
    },
    actions: {
      clickView: function clickView() {
        this.set("visible", !this.get("visible"));
        return false;
      },
      switchUser: function switchUser(u) {
        if (this.get("user") !== u.UniqueID) {
          this.get("session").adminChangeUser(u);
        }
        this.set("visible", !this.get("visible"));
      },
      ok: function ok() {
        this.sendAction('action');
      },
      toggleShow: function toggleShow() {
        this.toggleProperty("enabled");
      }
    }
  });
});