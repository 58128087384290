define("incus-vastaanota/pods/invoice/create/sell/index/controller", ["exports", "ember"], function (exports, _ember) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        countryselector: _ember["default"].inject.service(),

        actions: {
            initIframe: function initIframe() {
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var url = this.get("countryselector.url");

                var that = this;
                iFrameResize({
                    log: false,
                    initCallback: function initCallback(iframe) {
                        var userinfo = [];
                        userinfo.push.apply(userinfo, _toConsumableArray(that.get("session").get("user"))); //JSON.parse(localStorage.getItem("session"))
                        var locale = localStorage.getItem("locale");
                        if (url === "laskumappi" && locale === 'sv') {
                            locale = 'svfi';
                        }
                        userinfo.push({ locale: locale, app: "InvoiceBuilder" });
                        userinfo[0].entryPoint = url;
                        iframe.iFrameResizer.sendMessage(userinfo);
                    },
                    messageCallback: function messageCallback(msg) {
                        var command = JSON.parse(msg.message);
                        if (command.type === "setContract") {
                            apixclient.listContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then(function (data) {
                                if (!data.data.find(function (contract) {
                                    return contract.ContractType === "SuggestRouting";
                                })) {
                                    apixclient.setContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, that._requestDataString("SuggestRouting", "Laskumappi")).then(function (response) {
                                        console.log("SuggestRouting set");
                                    });
                                }
                            });
                        } else if (command.type === "updateCustomerData") {
                            apixclient.fetchInvoicingCustomerData(userData).then(function (data) {
                                if (data.Data) {
                                    data.Data.push(command.value);
                                } else {
                                    data.Data = [command.value];
                                }
                                apixclient.updateInvoicingCustomerData(userData, data).then(function (response) {
                                    console.log("Update customer", response);
                                });
                            });
                        } else if (command.type === "path") {
                            if (command.value === "invoice.view") {
                                that.transitionToRoute(command.value, command.params.storageId, { queryParams: command.params.queryParams });
                            } else {
                                that.transitionToRoute(command.value);
                            }
                        }
                    } }, '#laskunkirjoituskone');
            }
        },
        _requestDataString: function _requestDataString(contractType, parameters) {
            var doc = window.document.implementation.createDocument(null, "Request", null);
            var request = doc.firstChild;
            request.setAttribute("version", "1.0");
            var content = doc.createElement("Content");
            var group = doc.createElement("Group");

            group.appendChild(this._valueElement(doc, "ContractType", contractType));
            group.appendChild(this._valueElement(doc, "ContractStatus", "ACTIVE"));
            group.appendChild(this._valueElement(doc, "Parameters", parameters));

            content.appendChild(group);
            request.appendChild(content);
            var xs = new XMLSerializer();
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
            //header = "";
            return header + xs.serializeToString(doc);
        },
        _valueElement: function _valueElement(doc, type, text) {
            var el = doc.createElement("Value");
            el.setAttribute("type", type);
            el.textContent = text;
            return el;
        }

    });
});