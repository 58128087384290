define("incus-vastaanota/pods/login/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 0
              },
              "end": {
                "line": 16,
                "column": 0
              }
            },
            "moduleName": "incus-vastaanota/pods/login/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "infoText");
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-2x fa-exclamation-triangle");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "style", "color:orange");
            dom.setAttribute(el3, "href", "https://kampanja.apix.fi/verkkopostimerkit-jatkossa-laskumapista");
            dom.setAttribute(el3, "target", "_blank");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var element1 = dom.childAt(element0, [7]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[2] = dom.createMorphAt(element1, 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat", ["session.", ["get", "model.infoText", ["loc", [null, [11, 29], [11, 43]]]]], [], ["loc", [null, [11, 10], [11, 45]]]]], [], ["loc", [null, [11, 5], [11, 48]]]], ["inline", "t", [["subexpr", "concat", ["session.", ["get", "model.infoText", ["loc", [null, [12, 29], [12, 43]]]], "2"], [], ["loc", [null, [12, 10], [12, 49]]]]], [], ["loc", [null, [12, 5], [12, 52]]]], ["inline", "t", [["subexpr", "concat", ["session.", ["get", "model.infoText", ["loc", [null, [13, 29], [13, 43]]]], "3"], [], ["loc", [null, [13, 10], [13, 49]]]]], [], ["loc", [null, [13, 5], [13, 52]]]], ["inline", "t", [["subexpr", "concat", ["session.", ["get", "model.infoText", ["loc", [null, [13, 188], [13, 202]]]], "4"], [], ["loc", [null, [13, 169], [13, 208]]]]], [], ["loc", [null, [13, 164], [13, 211]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/login/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.isLaskumappi", ["loc", [null, [8, 6], [8, 24]]]]], [], 0, null, ["loc", [null, [8, 0], [16, 7]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/login/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "login-wrap");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "language-flags");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "src", "assets/images/fi_FI.jpg");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "src", "assets/images/sv_SE.jpg");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "src", "assets/images/en_GB.jpg");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "login");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "logo");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3, "name", "loginForm");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "center_div");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "center_div");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "center_div");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "class", "login-button");
        dom.setAttribute(el5, "type", "submit");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "center_div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "class", "forgotpw");
        dom.setAttribute(el5, "type", "submit");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element3, [5]);
        var element7 = dom.childAt(element2, [4]);
        var element8 = dom.childAt(element7, [5]);
        var element9 = dom.childAt(element8, [5, 1]);
        var element10 = dom.childAt(element8, [8, 1]);
        var morphs = new Array(11);
        morphs[0] = dom.createElementMorph(element4);
        morphs[1] = dom.createElementMorph(element5);
        morphs[2] = dom.createElementMorph(element6);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[7] = dom.createElementMorph(element9);
        morphs[8] = dom.createMorphAt(element9, 0, 0);
        morphs[9] = dom.createElementMorph(element10);
        morphs[10] = dom.createMorphAt(element10, 0, 0);
        return morphs;
      },
      statements: [["element", "action", ["setLang", "fi"], [], ["loc", [null, [3, 37], [3, 62]]]], ["element", "action", ["setLang", "svfi"], [], ["loc", [null, [4, 37], [4, 64]]]], ["element", "action", ["setLang", "en"], [], ["loc", [null, [5, 37], [5, 62]]]], ["block", "if", [["get", "model.infoText", ["loc", [null, [7, 6], [7, 20]]]]], [], 0, null, ["loc", [null, [7, 0], [17, 7]]]], ["inline", "t", ["session.loginTitle"], [], ["loc", [null, [20, 6], [20, 32]]]], ["inline", "input", [], ["type", "email", "value", ["subexpr", "@mut", [["get", "username", ["loc", [null, [23, 33], [23, 41]]]]], [], []], "placeholder", ["subexpr", "t", ["session.emailPlaceholder"], [], ["loc", [null, [23, 54], [23, 84]]]]], ["loc", [null, [23, 6], [23, 86]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "password", ["loc", [null, [26, 36], [26, 44]]]]], [], []], "placeholder", ["subexpr", "t", ["session.passwordPlaceholder"], [], ["loc", [null, [26, 57], [26, 90]]]]], ["loc", [null, [26, 6], [26, 92]]]], ["element", "action", ["login"], [], ["loc", [null, [29, 35], [29, 53]]]], ["inline", "t", ["session.loginAction"], [], ["loc", [null, [29, 68], [29, 95]]]], ["element", "action", ["forgotPW"], [], ["loc", [null, [36, 33], [36, 54]]]], ["inline", "t", ["session.forgotPassword"], [], ["loc", [null, [36, 69], [36, 99]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});