define('incus-vastaanota/pods/webshop/index/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    /*   apixclient: Ember.inject.service(),
      i18n: Ember.inject.service(),
      saldoupdater: Ember.inject.service(), */
    afterModel: function afterModel() {
      this.replaceWith('webshop.paymentcallback', '');
    }

    /*   model: function(params) {
        console.log("asd",params)
        console.log(this.get("type"))
          let model = Ember.Object.extend({
            webshopURL: config.webshopURL,
            showModal: false,
          }).create();
          return model;
      } */

  });
});