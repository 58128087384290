define('incus-vastaanota/pods/tools/users/route', ['exports', 'ember', 'incus-vastaanota/utils/email', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/countrycodes'], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsCountrycodes) {
  exports['default'] = _ember['default'].Route.extend({
    apixclient: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      return apixclient.listSubusers(userData, false).then(function (data) {

        var model = _ember['default'].Object.extend({
          editing: null,
          newUsername: '',
          newPass: '',
          newAllowPurchase: true,
          newAllowSell: true,
          newAllowAdminSelector: false,
          newAllowPayslip: false,
          editUsername: '',
          editPass: '',
          editAllowPurchase: true,
          editAllowSell: true,
          editAllowPayslip: false,
          editAllowAdminSelector: false,
          users: data,
          i18n: _this.get("i18n"),
          countryselector: _this.get("countryselector")
        }).create();

        return model;
      });
    },
    afterModel: function afterModel(model) {}

  });
});