define('incus-vastaanota/pods/application/route', ['exports', 'ember', 'incus-vastaanota/utils/poller', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaUtilsPoller, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),
    apixclient: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    queryParams: {
      skin: {}
    },

    init: function init() {
      this._super();

      this.get('session.saldo');
      var lang = undefined;
      if (_incusVastaanotaConfigEnvironment['default'].build === "fakturamappen") {
        this.set("i18n.locale", "sv");
        window.localStorage.setItem("locale", "sv");
      } else {
        if (window.localStorage) {
          lang = window.localStorage.getItem('locale');
          if (lang === 'sv' && String(window.location).toLowerCase().indexOf("laskumappi") > 0) {
            lang = 'svfi';
          }
        }
        if (lang) {
          _ember['default'].Logger.log("Setting language", lang);
          this.set("i18n.locale", lang);
        } else if (this.get("countryselector.url") === "laskumappi") {
          _ember['default'].Logger.log("Setting language fi");
          this.set("i18n.locale", "fi");
          window.localStorage.setItem("locale", "fi");
        } else if (this.get("countryselector.url") === "fakturamappen") {
          _ember['default'].Logger.log("Setting language sv");
          this.set("i18n.locale", "sv");
          window.localStorage.setItem("locale", "sv");
        }
      }
      this.get("saldoupdater").update_saldo(this);
      this.get("saldoupdater").add_saldo_poller(this);
    },

    model: function model(params) {
      var _this = this;

      var skin = params.skin || 'apix';
      var title = "";
      if (_incusVastaanotaConfigEnvironment['default'].build === "laskumappi") {
        title = "Laskumappi";
      } else {
        title = "Fakturamappen";
      }
      _ember['default'].$("head").append(_ember['default'].$("<link rel='stylesheet' href='assets/" + skin + ".css' type='text/css' media='screen' />"));
      _ember['default'].$("head").append(_ember['default'].$('<link rel="icon" href="assets/images/' + skin + '-favicon.png">'));
      _ember['default'].$("head").append(_ember['default'].$('<title>' + title + '</title>'));

      var model = _ember['default'].Object.extend({
        skin: skin,
        isLaskumappi: _incusVastaanotaConfigEnvironment['default'].build === "laskumappi",
        trimmedBusinessId: _ember['default'].computed('session.user.0.Ytunnus', function () {
          return _this.get('session.user.0.Ytunnus').replace(/[^\d-]/g, '');
        })
      }).create();

      return model;
    }

  });
});