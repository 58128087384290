define("incus-vastaanota/pods/invoice/create/sell/upload/route", ["exports", "ember", "incus-vastaanota/config/environment", "incus-vastaanota/utils/email", "incus-vastaanota/utils/sales-finvoice", "incus-vastaanota/models/invoice", "incus-vastaanota/utils/create-utils"], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsEmail, _incusVastaanotaUtilsSalesFinvoice, _incusVastaanotaModelsInvoice, _incusVastaanotaUtilsCreateUtils) {
  exports["default"] = _ember["default"].Route.extend({
    MAX_INVOICES: 15,

    apixclient: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    afterModel: function afterModel(resolved, transition) {},

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("invoice.create.sell").set("pageTitle", this.get("i18n").t("createSalesView.uploadTitle").toString());
    },

    model: function model(param) {
      var model = _ember["default"].Object.extend({
        uploadTypes: ["Finvoice", "TEAPPS", "Finvoice 3.0"],
        selectedUploadType: "Finvoice",

        senders: null,
        fileList: null,
        is_sent: null,
        missingFields: null,
        fields: [{
          fieldName: "fileName",
          numeric: false,
          sortable: true
        }, {
          fieldName: "fileSize",
          numeric: true,
          sortable: true
        }, {
          fieldName: "fileType",
          numeric: false,
          sortable: false
        }, {
          fieldName: "Amount",
          numeric: true,
          sortable: false
        }, {
          fieldName: "Status",
          numeric: false,
          sortable: false
        }, {
          fieldName: "Response",
          numeric: false,
          sortable: false
        }]
      }).create({
        fileList: [],
        senders: 2,
        is_sent: false
      });

      return model;
    },

    actions: {
      getMetadata: function getMetadata(file_item) {
        var showDetails = _ember["default"].get(file_item, "showDetails");

        if (showDetails === true) {
          _ember["default"].set(file_item, "showDetails", false);
          return;
        } else {
          _ember["default"].set(file_item, "showDetails", true);
          return;
        }
      },

      fileChanged: function fileChanged(file_item) {
        console.log(["fileChanged", file_item]);

        file_item.send_status = "notsent";
        file_item.freetext = "";

        var files = this.get("controller.model.fileList");
        files.pushObject(file_item);

        var b = new Uint8Array(file_item.data);
        var string = new TextDecoder("ISO-8859-15").decode(b);
        //console.log(file_item.data, string);_make_deliverymethod_query

        if (file_item.type === "text/xml") {
          var invoices = this._split_invoices(string);
          console.log(invoices);
          file_item.invoices = invoices;

          if (invoices.length > this.get("MAX_INVOICES")) {
            this.set("controller.model.is_sent", true);

            var error_message = this.get("i18n").t("common.error").toString() + ": " + this.get("i18n").t("createSalesView.tooManyInvoices").toString();
            error_message += ", " + this.get("i18n").t("createSalesView.max").toString() + " " + this.get("MAX_INVOICES");

            this.set("controller.model.missingFields", [error_message]);
          }
        } else if (file_item.type === "application/x-zip-compressed") {
          var zip = new JSZip();
          zip.load(file_item.data);

          var keys = Object.keys(zip.files);

          for (var i = keys.length - 1; i >= 0; i--) {
            if (keys[i].match(/\.(xml|XML)$/)) {
              console.log(keys[i]);

              var xml_uint8_object = zip.file(keys[i]).asUint8Array();

              console.log(["AA", xml_uint8_object]);

              //let b = new Uint8Array(xml_file_object);
              var _string = new TextDecoder("ISO-8859-15").decode(xml_uint8_object);
              var invoices = this._split_invoices(_string);
              console.log(invoices);
              file_item.invoices = invoices;
              break;
            }
          }

          console.log(zip);
        }

        if (file_item.invoices) {
          var invoices = file_item.invoices;
          for (var i = invoices.length - 1; i >= 0; i--) {
            var meta = {};

            if (invoices[i].soap && invoices[i].soap != "") {
              var smeta = this._harvest_soap_details(invoices[i].soap);
              console.log(smeta);
              meta = Object.assign(meta, smeta);
            }

            var fmeta = this._harvest_finvoice_details(invoices[i].finvoice);
            console.log(fmeta);

            invoices[i].meta = Object.assign(meta, fmeta);
          }

          this._make_deliverymethod_query(file_item);
        }
      },

      uploadFiles: function uploadFiles() {
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];
        var files = this.get("controller.model.fileList");

        var resolve_send = function resolve_send(data) {};
        var type = this.get("controller.model.selectedUploadType");
        var channel = "LaskumappiUpload";
        switch (type) {
          case "Finvoice":
            channel = "LaskumappiUpload";
            break;
          case "TEAPPS":
            channel = "LaskumappiTEAPPSUpload";
            break;
          case "Finvoice 3.0":
            channel = "LaskumappiUpload3";
            break;
          default:
            channel = "LaskumappiUpload";
            break;
        }
        var promises = [];
        for (var i = 0; i < files.length; i++) {
          var file_item = files[i];

          var p = apixclient.sendInvoiceZip(userData.TransferID, userData.TransferKey, file_item.data, channel);
          promises.pushObject({ promise: p, file: file_item });
        }

        for (var i = 0; i < promises.length; i++) {
          var item = promises[i];
          this._send_invoice_to_api(item);
        }
      },

      removeFiles: function removeFiles() {
        this.set("controller.model.fileList", []);
        this.set("controller.model.is_sent", false);
        this.set("controller.model.missingFields", []);
      }
    },

    _throttled_send_invoice_to_api: function _throttled_send_invoice_to_api(item) {
      this.set("controller.model.is_sent", true);

      var senders_availabe = this.get("controller.model.senders");

      if (senders_availabe > 0) {
        console.log(["Send", item]);
        this._send_invoice_to_api(item);
      } else {
        console.log(["Delay send of", item]);
        setTimeout(500, function () {
          this._throttled_send_invoice_to_api(item);
        });
      }
    },

    _send_invoice_to_api: function _send_invoice_to_api(item) {
      var _this = this;

      var file_item = item.file;

      this.set("controller.model.is_sent", true);

      var senders_availabe = this.get("controller.model.senders");
      this.set("controller.model.senders", senders_availabe - 1);

      _ember["default"].RSVP.Promise.resolve(item.promise).then(function (data) {
        var senders_availabe = _this.get("controller.model.senders");
        _this.set("controller.model.senders", senders_availabe + 1);

        _ember["default"].Logger.log(data);
        _ember["default"].set(file_item, "send_status", data.callStatus.success);

        if (data.callStatus.freeText) {
          _ember["default"].set(file_item, "freetext", data.callStatus.freeText);
        }

        if (data.callStatus.success === "OK") {
          var len = data.data.length;
          console.log([data, data.data]);

          data.data.forEach(function (group) {
            if (group.ValidateText) {
              var ft = _ember["default"].get(file_item, "freetext");

              if (!ft) {
                ft = group.ValidateText;
              } else {
                ft += "; \n" + group.ValidateText;
              }

              _ember["default"].set(file_item, "freetext", ft);
            }
          });

          _ember["default"].set(file_item, "showDetails", false);
          _ember["default"].set(file_item, "send_data", data.data[0]);
        }
      });
    },

    _split_invoices: function _split_invoices(xml_string) {
      var invoices_with_soap = xml_string.split("</Finvoice>");

      var out_invoices = [];

      for (var i = 0; i < invoices_with_soap.length; i++) {
        console.log(["invoices_with_soap[i]", i, invoices_with_soap[i]]);

        var current_invoice = invoices_with_soap[i];

        if (current_invoice.replace(/\s+/, "").length > 0) {
          current_invoice += "</Finvoice>";

          var pieces = current_invoice.split("<?xml ");
          var soap = pieces[0];
          var finvoice = "<?xml " + pieces[1];

          if (soap == "") {
            soap = null;
          }

          out_invoices.pushObject({
            soap: soap,
            finvoice: finvoice,
            uuid: this._generateUUID()
          });
        }
      }

      return out_invoices;
    },

    _harvest_soap_details: function _harvest_soap_details(soap_string) {
      var safe_get_element_value = function safe_get_element_value(element, element_tag) {
        try {
          var value = element.getElementsByTagName(element_tag)[0].childNodes[0].nodeValue;
          //value = unescape( encodeURIComponent( value ) );
          //value = decodeURIComponent( escape( value ) );
          return value;
        } catch (e) {
          return null;
        }
      };

      var parser = new window.DOMParser();
      var meta = {};

      try {
        var xdoc = parser.parseFromString(soap_string, "text/xml");

        var _from = xdoc.getElementsByTagName("eb:From");
        console.log(["_harvest_soap_details", "_from", _from]);
        for (var i = 0; i < _from.length; i++) {
          var branch = _from[i];

          var partyid = safe_get_element_value(branch, "eb:PartyId");
          var role = safe_get_element_value(branch, "eb:Role");

          if (role && partyid) {
            meta["From" + role] = partyid;
          }
        }

        var _to = xdoc.getElementsByTagName("eb:To");
        console.log(["_harvest_soap_details", "_to", _to]);
        for (var i = 0; i < _to.length; i++) {
          var branch = _to[i];

          var partyid = safe_get_element_value(branch, "eb:PartyId");
          var role = safe_get_element_value(branch, "eb:Role");

          if (role && partyid) {
            meta["To" + role] = partyid;
          }
        }
      } catch (e) {
        console.error([soap_string, meta, e]);
      }

      meta.deliverymethod = "unknown";

      return meta;
    },

    _harvest_finvoice_details: function _harvest_finvoice_details(finvoice_string) {
      var convmap2 = {
        "195:133": "197", // Å
        "195:165": "229", // å
        "195:132": "196", // Ä
        "195:8364": "228", // ä
        "195:150": "214", // Ö
        "195:182": "246" // ö
      };

      var safe_get_element_value = function safe_get_element_value(element, element_tag) {
        try {
          var _ret = (function () {
            var value = element.getElementsByTagName(element_tag)[0].childNodes[0].nodeValue;

            try {
              value = decodeURIComponent(window.escape(value));
            } catch (e) {}

            var probably_utf8 = false;
            var asciiKeys = [];
            for (var i = 0; i < value.length; i++) {
              var ascii = value[i].charCodeAt(0);
              asciiKeys.push(ascii);
              if (ascii >= 195) {
                probably_utf8 = true;
              }
            }

            var asciicr = asciiKeys.join(":");

            if (probably_utf8) {
              var keys = Object.keys(convmap2);

              keys.map(function (key) {
                var value = convmap2[key];
                asciicr = asciicr.replace(new RegExp(key, "g"), value);
              });

              var pieces = asciicr.split(":");
              pieces = pieces.map(function (elem) {
                return String.fromCharCode(parseInt(elem));
              });
              value = pieces.join("");
            }

            return {
              v: value
            };
          })();

          if (typeof _ret === "object") return _ret.v;
        } catch (e) {
          console.log(["fv safe_get_element_value error", element, e]);
          return null;
        }
      };

      var parser = new window.DOMParser();
      var meta = {};
      try {
        var xdoc = parser.parseFromString(finvoice_string, "text/xml");

        meta.einvoice_address = safe_get_element_value(xdoc, "ToIdentifier");
        meta.operator = safe_get_element_value(xdoc, "ToIntermediator");

        var s = {
          bid: safe_get_element_value(xdoc, "SellerPartyIdentifier"),
          name: safe_get_element_value(xdoc, "SellerOrganisationName"),
          ovt: safe_get_element_value(xdoc, "SellerOrganisationUnitNumber")
        };
        meta.seller = s;

        // if InvoiceRecipient
        if (safe_get_element_value(xdoc, "InvoiceRecipientOrganisationName")) {
          var ir = {
            bid: safe_get_element_value(xdoc, "InvoiceRecipientPartyIdentifier"),
            name: safe_get_element_value(xdoc, "InvoiceRecipientOrganisationName"),
            ovt: safe_get_element_value(xdoc, "InvoiceRecipientOrganisationUnitNumber")
          };

          meta.receiver = ir;
        } else {
          var b = {
            bid: safe_get_element_value(xdoc, "BuyerPartyIdentifier"),
            name: safe_get_element_value(xdoc, "BuyerOrganisationName"),
            ovt: safe_get_element_value(xdoc, "BuyerOrganisationUnitNumber")
          };
          meta.receiver = b;
        }

        meta.invoice_number = safe_get_element_value(xdoc, "InvoiceNumber");
        meta.invoice_date = safe_get_element_value(xdoc, "EpiDate");
        meta.due_date = safe_get_element_value(xdoc, "EpiDateOptionDate");
        meta.invoice_sum = safe_get_element_value(xdoc, "EpiInstructedAmount");
      } catch (e) {
        console.error([finvoice_string, meta, e]);
      }

      meta.deliverymethod = "unknown";

      return meta;
    },

    _make_deliverymethod_query: function _make_deliverymethod_query(file_item) {
      var _this2 = this;

      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      var xescape = function xescape(strin) {
        return _ember["default"].$("<div/>").text(strin).html();
      };

      var xml = "<Request><Content>\n";

      var ordered_invoices = {};

      if (!file_item.invoices) {
        return;
      }

      for (var i = 0; i < file_item.invoices.length; i++) {
        var invoice = file_item.invoices[i];

        ordered_invoices[invoice.uuid] = invoice;

        xml += "<Group>\n";
        xml += '<Value type="DocumentID">' + xescape(invoice.uuid) + "</Value>\n";

        /*
          xml += "<Value type=\"SenderName\">" + xescape(invoice.meta.seller.bid) + "</Value>\n";
          xml += "<Value type=\"SenderYtunnus\">" + xescape(invoice.meta.seller.name) + "</Value>\n";
          */
        xml += '<Value type="SenderName">' + xescape(userData.Name) + "</Value>\n";
        xml += '<Value type="SenderYtunnus">' + xescape(userData.Ytunnus) + "</Value>\n";

        xml += '<Value type="ReceiverYtunnus">' + xescape(invoice.meta.receiver.bid) + "</Value>\n";
        xml += '<Value type="ReceiverName">' + xescape(invoice.meta.receiver.name) + "</Value>\n";
        xml += '<Value type="ReceiverOVT">' + xescape(invoice.meta.receiver.ovt) + "</Value>\n";

        if (invoice.meta.ToReceiver) {
          xml += '<Value type="ReceivereInvoiceAddress">' + xescape(invoice.meta.ToReceiver) + "</Value>\n";
        } else if (invoice.meta.einvoice_address) {
          xml += '<Value type="ReceivereInvoiceAddress">' + xescape(invoice.meta.einvoice_address) + "</Value>\n";
        }

        xml += "</Group>\n";
      }

      xml += "</Content></Request>";

      apixclient.askDeliveryMethod(userData.TransferID, userData.TransferKey, xml).then(function (data) {
        for (var i = 0; i < data.data.length; i++) {
          var item = data.data[i];
          var invoice = ordered_invoices[item.DocumentID];

          if (invoice) {
            item.NormalizedChannelName = _this2._messageTypeConversion(item.ChannelName);
            console.log(item.NormalizedChannelName);
            invoice.meta.deliverymethod = item;
          }
        }
      });
    },

    _generateUUID: function _generateUUID() {
      var d = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    },

    _messageTypeConversion: function _messageTypeConversion(real_type) {
      console.log(["_messageTypeConversion", real_type]);
      var convmap = {
        eInvoice: "webBill",
        Paper: "letter",
        email: "email",
        EDI: "edi",
        Netpost: "webPost",
        "e-lasku": "eBill",
        Suoramaksu: "directPayment"
      };

      if (convmap[real_type]) {
        return convmap[real_type];
      } else {
        return "unknown";
      }
    }
  });
});