define("incus-vastaanota/pods/forgotpw/route", ["exports", "ember", "incus-vastaanota/config/environment"], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),
    apixclient: _ember["default"].inject.service(),
    spinner: _ember["default"].inject.service(),

    beforeModel: function beforeModel(param) {
      if (this.get("session.user")) {
        this.transitionTo("traffic.pitstop.list");
      }
    },
    model: function model(param) {
      var _this = this;

      return new _ember["default"].RSVP.Promise(function (resolve) {
        _ember["default"].run.later(function () {
          _this.checkRequest(param.id).then(function (res) {
            var validRequest = res.validRequest;
            var requesting = res.requesting;

            var model = _ember["default"].Object.extend({
              registrationUrl: _incusVastaanotaConfigEnvironment["default"].registrationUrl,
              passwordChangeUrl: _incusVastaanotaConfigEnvironment["default"].passwordChangeUrl,
              username: "",
              newPass: "",
              repeatPass: "",
              passwordsValid: "",
              requesting: requesting,
              validRequest: validRequest,
              id: param.id,
              passwordsObserver: _ember["default"].observer("newPass", "repeatPass", function () {
                var newPass = this.get("newPass");
                var repeatPass = this.get("repeatPass");
                if (newPass && repeatPass) {
                  if (newPass === repeatPass && newPass.length > 3) {
                    this.set("passwordsValid", "is-valid");
                  } else {
                    this.set("passwordsValid", "is-invalid");
                  }
                } else {
                  this.set("passwordsValid", "");
                }
              })
            }).create();
            resolve(model);
          });
        });
      });
    },
    checkRequest: function checkRequest(id) {
      var _this2 = this;

      return new _ember["default"].RSVP.Promise(function (resolve) {
        var validRequest = false;
        var requesting = true;
        if (id) {
          requesting = false;
          _this2.get("apixclient").checkPasswordRenewal(id).then(function (res) {
            validRequest = true;
            resolve({ requesting: requesting, validRequest: validRequest });
            return;
          })["catch"](function (error) {
            validRequest = false;
            resolve({ requesting: requesting, validRequest: validRequest });
            return;
          });
        } else {
          resolve({ requesting: requesting, validRequest: validRequest });
          return;
        }
      });
    }
  });
});