define("incus-vastaanota/pods/login/route", ["exports", "ember", "incus-vastaanota/config/environment"], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
    exports["default"] = _ember["default"].Route.extend({
        session: _ember["default"].inject.service(),
        countryselector: _ember["default"].inject.service(),
        i18n: _ember["default"].inject.service(),
        queryParams: {
            from: {},
            transition: {},
            destination: {}
        },

        beforeModel: function beforeModel(param) {
            var isLaskumappi = this.get("countryselector.url") === 'laskumappi';

            if (this.get("session.user") && param.queryParams.from && isLaskumappi) {
                if (param.queryParams.from == "webshop") {
                    this.transitionTo("webshop");
                }
            } else if (this.get("session.user")) {
                this.transitionTo("traffic");
            }
        },
        model: function model(params) {
            var infoText = "";
            if (params.from) {
                switch (params.from) {
                    case "hallinta":
                        {
                            infoText = "hallinta";
                            break;
                        }
                    case "webshop":
                        {
                            infoText = "webshop";
                            break;
                        }

                }
            }
            var isLaskumappi = this.get("countryselector.url") === 'laskumappi';
            var model = _ember["default"].Object.extend({
                registrationUrl: _incusVastaanotaConfigEnvironment["default"].registrationUrl,
                passwordChangeUrl: _incusVastaanotaConfigEnvironment["default"].passwordChangeUrl,
                isLaskumappi: isLaskumappi,
                infoText: infoText
            }).create();
            _ember["default"].Logger.log("setting model for login");
            return model;
        }

    });
});