define('incus-vastaanota/pods/mixins/clickoutside', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    onOutsideClick: _ember['default'].K,

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = $(event.target);
      if (!$element.is($target) && $element.has($target).length === 0) {
        this.onOutsideClick();
      }
      /*     if (!$target.closest($element).length) {
            this.onOutsideClick();
          } */
    },

    setupOutsideClickListener: _ember['default'].on('didInsertElement', function () {

      var clickHandler = this.get('handleOutsideClick').bind(this);

      return _ember['default'].$(document).on('click', clickHandler);
    }),

    removeOutsideClickListener: _ember['default'].on('willDestroyElement', function () {

      var clickHandler = this.get('handleOutsideClick').bind(this);

      return _ember['default'].$(document).off('click', _ember['default'].run.cancel(this, clickHandler));
    })
  });
});