define('incus-vastaanota/models/comment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    i18n: _ember['default'].inject.service(),
    commentNumber: null,
    timeStamp: null,
    approvalStatus: null,
    email: null,
    content: null,
    originalString: null,
    edited: null,

    formattedTimeStamp: (function () {
      var date = moment(this.get('timeStamp'), 'YYYYMMDDHHmmss');
      return date.format('DD.MM.YYYY');
    }).property('timeStamp'),

    formattedEditStamp: (function () {
      var edited = this.get("edited");
      if (edited) {
        edited = edited.split("edit:")[1];
        var parts = edited.split(":");
        return parts[0] + " " + moment(parts[1], 'YYYYMMDDHHmmss').format('hh:mm DD.MM.YYYY');
      }
      return null;
    }).property('edited'),

    // comment.0: "20150612071553   POSITIVE    pekka.malli@malli.fi    Testikommentti 1"
    parseComment: function parseComment(commentNumber, data) {
      this.set("commentNumber", commentNumber);
      this.set("originalString", data);
      var re = /^(\d+)\t(POSITIVE|NEGATIVE|NEUTRAL)\t([^\t]*)\t([\s\S]*)?$/m;
      var result = re.exec(data);
      if (result && result[0]) {
        this.set("timeStamp", result[1]);
        this.set("approvalStatus", result[2]);
        this.set("email", result[3]);
        if (result[4]) {
          var idx = result[4].indexOf("\tedit:");
          if (idx > -1) {
            this.set("content", result[4].substr(0, idx));
            this.set("edited", result[4].substr(idx + 1, result[4].length));
          } else {
            this.set("content", result[4]);
          }
        } else {
          this.set("content", result[4]);
        }
      } else {
        _ember['default'].Logger.log("String not recognised as a comment: " + data);
      }
    }

  });
});