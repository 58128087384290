define('incus-vastaanota/pods/webshop/paymentcallback/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),

    model: function model(params) {
      console.log("pcb", params);
      var model = _ember['default'].Object.extend({
        webshopURL: _incusVastaanotaConfigEnvironment['default'].webshopURL,
        showModal: false,
        paymentParams: params
      }).create();
      return model;
    }

  });
});