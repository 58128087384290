define("incus-vastaanota/pods/components/invoice-comment/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    session: _ember["default"].inject.service(),
    auth: null,
    userEmail: "ss",
    editing: false,
    commentText: "",
    editStamp: "",
    init: function init() {
      this._super.apply(this, arguments);
      this.set("commentText", this.get("content"));
    },

    actions: {
      editComment: function editComment() {
        this.set("editing", true);
      },
      saveComment: function saveComment(status) {
        var params = {
          approvalStatus: status,
          email: this.get("userEmail"),
          text: this.get("commentText"),
          number: this.get("number")
        };
        this.sendAction('action', params);
        this.set("editing", false);
      },
      switchUser: function switchUser(evt) {
        this.set("user", evt.target.value);
        var u = this.get("users").find(function (u) {
          return u.UniqueID === evt.target.value;
        });
        this.get("session").adminChangeUser(u);
      },
      cancel: function cancel() {
        this.set("editing", false);
      }
    }
  });
});