define("incus-vastaanota/pods/webshop/paymentcallback/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/webshop/paymentcallback/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("iframe{width: 1px;min-width: 100%;}");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("iframe");
        dom.setAttribute(el1, "id", "webshop");
        dom.setAttribute(el1, "frameborder", "0");
        dom.setAttribute(el1, "scrolling", "no");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'src');
        morphs[1] = dom.createAttrMorph(element0, 'onload');
        return morphs;
      },
      statements: [["attribute", "src", ["get", "model.webshopURL", ["loc", [null, [3, 27], [3, 43]]]]], ["attribute", "onload", ["subexpr", "action", ["initIframe"], [], ["loc", [null, [3, 84], [3, 107]]]]]],
      locals: [],
      templates: []
    };
  })());
});