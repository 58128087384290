define('incus-vastaanota/pods/registration/receipt/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    i18n: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    model: function model(param) {
      var businessStr = this.get("countryselector.url") === "fakturamappen" ? "Apix Messaging Ab" : "Apix Messaging Oy";
      var businessId = this.get("countryselector.url") === "fakturamappen" ? "556964-9287" : "2332748-7";
      var formData = window.registrationFormData;
      var termsUrl = _incusVastaanotaConfigEnvironment['default'].clientHostRoot + '/#/terms';
      if (!formData || typeof formData !== 'object') {

        this.get('notify').error(this.get("i18n").t("registration.receipt.dataError").toString(), {
          closeAfter: null
        });

        this.transitionTo('registration.info');
      }
      delete window.registrationFormData;
      var userBid = formData.businessId.field.replace("-", "");
      var vatIdSE = "SE" + userBid + "01";

      var isLaskumappi = this.get("countryselector.url") === "laskumappi";
      var receiveIsOn = formData.categories.find(function (c) {
        return c.name === "receive";
      }).field;

      var model = _ember['default'].Object.extend({
        receiveIsOn: receiveIsOn,
        isLaskumappi: isLaskumappi,
        businessStr: businessStr,
        businessId: businessId,
        termsUrl: termsUrl,
        userBid: userBid,
        vatId: vatIdSE,
        formData: formData,
        date: moment().format("DD/MM/YYYY")
      }).create();
      return model;
    },
    actions: {
      printReceipt: function printReceipt() {
        window.print();

        /*       let w=window.open();
              console.log(document.getElementsByClassName('printArea')[0].innerHTML) */
        /*       w.document.write('<style'+`
              div.receipt-wrapper {
                background-color: white;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0.5em;
                padding-right: 0.5em;
                padding-bottom: 0.5em;
                max-width: 800px;
              }
              
              h2 {
                color: #96cad4;
              }
              
              table {
                width: 100%;
              }
              
              table.info th {
                width: 25%;
              }
              
              tr:nth-child(odd) th {
                background-color: darken(#fff, 2);
              }
              
              `+'</style>') */
        /*       console.log(document.getElementById('supplier-letter').innerHTML)
              w.document.write(document.getElementById('supplier-letter').innerHTML); */

        //  w.document.write(document.getElementsByClassName('printArea')[0].innerHTML);
        /*       w.print();
              w.close(); */
      },
      done: function done() {
        this.transitionTo("login");
      }
    }

  });
});